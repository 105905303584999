import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IContact } from '../shared/models/contactUs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }


contactUs(productRequest: IContact): Observable<IContact>  {
  return this.http.post<IContact>(this.baseUrl +"Contact/send", productRequest).pipe(
	map(
	  (response) => {
		  if (response) {
		return response
		  }
	  },
	  (error) => {
		  if (error) {
		return error
		  }
	   }
	)
	)
  }
}
