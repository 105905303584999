<div class="page-header">
    <div class="container">
       
    </div>
  </div>
  
  <div class="container">
    <div class="row top-row">
        <div class="col-lg-2 row end-xs" >
            <a routerLink="/userOrder" color="primary" mat-raised-button>
                <mat-icon>chevron_left</mat-icon>
                Go Back
            </a>
        </div>
    </div>
  <br>
  <br>
    <div class="row">
        <div class="col-lg-12">
            <form #productDetailsForm="ngForm">
                <div class="row">
                    <div class="col-lg-3">
                      <mat-form-field appearance="outline">
                        <mat-label>First Name: </mat-label>
                        <input type="text" matInput [(ngModel)]="order.shipToAddress.firstName" name="firstName">
                       </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field appearance="outline">
                          <mat-label>Last Name: </mat-label>
                          <input type="text" matInput [(ngModel)]="order.shipToAddress.lastName" name="lastName">
                         </mat-form-field>
                      </div>
                      <div class="col-lg-3">
                        <mat-form-field appearance="outline">
                          <mat-label>Phone Number: </mat-label>
                          <input type="text" matInput [(ngModel)]="order.shipToAddress.phoneNumber" name="phoneNumber">
                         </mat-form-field>
                      </div>
                </div>
             <div class="row">
                <div class="col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Email: </mat-label>
                    <input type="text" matInput [(ngModel)]="order.buyerEmail" name="buyerEmail">
                   </mat-form-field>
                </div>
                <div class="col-lg-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Date: </mat-label>
                        <input type="text" matInput [(ngModel)]="order.orderDate" name="orderDate">
                       </mat-form-field>
                  </div>
                 <div class="col-lg-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Street Name: </mat-label>
                      <input type="text" matInput [(ngModel)]="order.shipToAddress.street" name="street">
                     </mat-form-field>
                  </div>
             </div>
             <div class="row">
                <div class="col-lg-3">
                    <mat-form-field appearance="outline">
                      <mat-label>City: </mat-label>
                      <input type="text" matInput [(ngModel)]="order.shipToAddress.city" name="city">
                     </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="outline">
                      <mat-label>State: </mat-label>
                      <input type="text" matInput [(ngModel)]="order.shipToAddress.state" name="state">
                     </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Zip Code: </mat-label>
                      <input type="text" matInput [(ngModel)]="order.shipToAddress.zipcode" name="zipCode">
                     </mat-form-field>
                  </div>
             </div>
             <div class="row">
                <div class="col-lg-3">
                    <mat-form-field appearance="outline">
                      <mat-label>subtotal: </mat-label>
                      <input type="text" matInput [(ngModel)]="order.subtotal" name="subtotal">
                     </mat-form-field>
                  </div>
                  <div class="col-lg-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Shipping price: </mat-label>
                      <input type="text" matInput [(ngModel)]="order.shippingPrice" name="shippingPrice">
                     </mat-form-field>
                  </div>
                <div class="col-lg-3">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Total: </mat-label>
                  <input type="text" matInput [(ngModel)]="order.total" name="total">
                 </mat-form-field>
              </div>
              <div class="col-lg-3">
                <mat-form-field appearance="outline">
                  <mat-label>Zip Code: </mat-label>
                 
                 </mat-form-field>
              </div>
             </div>
            <!--<div class="row">
              <div class="col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>subtotal: </mat-label>
                    <input type="text" matInput [(ngModel)]="order." name="subtotal">
                   </mat-form-field>
                    <input type="text" matInput [(ngModel)]="order." name="zipCode">
                </div>
                <div class="col-lg-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Shipping price: </mat-label>
                    <input type="text" matInput [(ngModel)]="order.shippingPrice" name="shippingPrice">
                   </mat-form-field>
                </div>
              <div class="col-lg-3">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Total: </mat-label>
                <input type="text" matInput [(ngModel)]="order.total" name="total">
               </mat-form-field>
            </div>
           </div>--> 
             
              <div class="row">
                <div class="col-xs-3">
                  <div class="row" >
                  <!---  <div class="col-3"><button mat-raised-button color="warn" (click)="onDelete()">Delete</button></div>-->
                  </div>
                </div>
              </div>
            </form>
         </div>
         <div class="col-lg-6">
          
        </div>
    </div>
  
  
  </div>
  <br>
  <br>
  <br>
  
