
<div class="container">
  <div class="row top-row">
    <div class="col-lg-2 row end-xs" >
        <a routerLink="/adminProduct01102023" color="primary" mat-raised-button>
            <mat-icon>chevron_left</mat-icon>
            Go Back
        </a>
    </div>
</div>
<br>
<br>
<div fxLayout fxLayoutAlign="center center">
  <mat-form-field fxFlex="40%">
    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>
  <div class="d-flex justify-content-center mt-5">
<div class="row ml-1">
    <div class="mat-elevation-z8"> 
     <table mat-table [dataSource]="dataSource" matSort matSortStart="desc">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="theader">Order Number</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
       <ng-container matColumnDef="buyerEmail">
         <th mat-header-cell *matHeaderCellDef mat-sort-header class="theader th">buyer Email</th>
         <td mat-cell *matCellDef="let element" class="th"> {{element.buyerEmail}} </td>
       </ng-container>
       <ng-container matColumnDef="orderDate" >
         <th mat-header-cell *matHeaderCellDef mat-sort-header class="theader th" style="padding-left: 20px;">order Date</th>
         <td mat-cell *matCellDef="let element" class="th"> {{element.orderDate | date: 'MM/dd/yyyy'}} </td>
       </ng-container>
       <ng-container matColumnDef="shipToAddress.city" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="theader th" >City</th>
        <td mat-cell *matCellDef="let element" class="th"> {{element.shipToAddress.city}} </td>
      </ng-container>
       <ng-container matColumnDef="shipToAddress.firstName">
         <th mat-header-cell *matHeaderCellDef mat-sort-header class="theader th"> First Name </th>
         <td mat-cell *matCellDef="let element" class="th"> {{element.shipToAddress.firstName}} </td>
       </ng-container>
       <ng-container matColumnDef="shipToAddress.lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="theader th"> Last Name </th>
        <td mat-cell *matCellDef="let element" class="th"> {{element.shipToAddress.lastName}} </td>
      </ng-container>
      <ng-container matColumnDef="shipToAddress.phoneNumber">
        <th mat-header-cell *matHeaderCellDef class="theader th"> Phone Number </th>
        <td mat-cell *matCellDef="let element" class="th"> {{element.shipToAddress.phoneNumber}} </td>
      </ng-container>
        <ng-container matColumnDef="edit">
         <th mat-header-cell *matHeaderCellDef > </th>
         <td mat-cell *matCellDef="let element" class="th">
           <a href="/viewOrder/{{element.id}}" > <mat-icon color="primary">edit</mat-icon></a> 
         </td>
       </ng-container>
       <ng-container matColumnDef="delete">
         <th mat-header-cell *matHeaderCellDef ></th>
         <td mat-cell *matCellDef="let element;  let j ;">
           <a href="/viewOrder/{{element.id}}" > <mat-icon color="warn">delete</mat-icon></a> 
         </td>
       </ng-container>
       
       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
       <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
       
     </table>
     <mat-paginator style="color: orange"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]"
     aria-label="Select page">
   </mat-paginator>
   </div>
   </div>
  </div>
</div>
<app-order-items></app-order-items>
<!--<div class="container mt-5">
  <div class="row">
      <div class="col-12">
          <table class="table table-hover" style="cursor: pointer;">
              <thead class="thead-light">
                  <tr>
                      <th>Order</th>
                      <th>Date</th>
                      <th>Buyer Email</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>First Name</th>
                      <th>Price</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let order of orders" >
                      <td>{{order.id}}</td>
                      <td>{{order.orderDate | date: 'medium'}}</td>
                      <td>{{order.buyerEmail}}</td>
                      <td>{{order.total | currency}}</td>
                      <td>{{order.status}}</td>
                      <td>{{order.shipToAddress.firstName}}</td>
                      <td>{{order.orderItems.price}}</td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
</div>-->
<br>
<br>
