import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Product } from 'src/app/shared/models/product';
import { ShopParams } from 'src/app/shared/models/shopParams';
import { IBrand } from 'src/app/shared/models/brand';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-admin-product-brand',
  templateUrl: './admin-product-brand.component.html',
  styleUrls: ['./admin-product-brand.component.css']
})
export class AdminProductBrandComponent implements OnInit {
  productBrandList: any = [];
  depart: any;
  productId: string | null | undefined;
  //products: IProduct[];
  brands: IBrand[];
  shopParams: ShopParams;
  filterString = '';
  returnUrl: string;
  brand: IBrand = {
    id: 0,
    name: '',
  }

  displayedColumns: string[] = ['id','name','edit','delete'];
  dataSource: MatTableDataSource<IBrand> = new MatTableDataSource<IBrand>();

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  constructor(private http: HttpClient, public service : AdminService, 
    private route: ActivatedRoute, private snackbar: MatSnackBar,private router: Router) { }

  ngOnInit(): void {
    this.getBrands();
  }
  getBrands() {
    this.service.getBrands()
    .subscribe(
      (successResponse) => {
         this.brands = successResponse;
         this.dataSource = new MatTableDataSource<IBrand>(this.brands);
        if (this.matPaginator) {
          this.dataSource.paginator = this.matPaginator;
        }
      },
      (errorResponse) => {
        console.log(errorResponse)
      });
    }
 // getBrands() {
   // this.service.getBrands().subscribe(response => {
   //   this.brands = [{id: 0, name: 'All'}, ...response];
   // }, error => {
   //   console.log(error);
   // })
 // }
  filterProducts() {
    this.dataSource.filter = this.filterString.trim().toLowerCase();
   }
   /*onDelete() {
   if (confirm("Are you sure to delete ")) {
     this.service.productbrandDelete(this.productId)
     .subscribe(
      (successResponse) => {
        console.log(successResponse);
       this.snackbar.open('Product deleted successfully', undefined, {
        duration: 2000
       });
       setTimeout(() => {
        this.router.navigateByUrl('adminProduct01102023');
      }, 2000);
      },
      (errorResponse) => {
        console.log(errorResponse)
      } );*/
   // }
  // }
  onDelete(item: any) {
    if (confirm('Are you sure??')) {
      //console.log('welcome');
      this.service.productbrandDelete(item).subscribe(data => {
       // alert(data.toString());
        //this.refreshDepList();
      })
    }
  }
  refreshDepList() {
    this.service.getBrands().subscribe(data => {
      this.productBrandList = data;
      //this.DepartmentListWithoutFilter = data;
    });
  }
  editClick(item: any) {
    this.depart = item;
   // this.ModalTitle = "Edit Department";
   // this.ActivateAddEditDepartComp = true;
  }
}
