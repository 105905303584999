<div class="container">
   
  <br>
  <br>
    <div class="d-flex justify-content-center mt-5">
  <div class="row ml-1">
      <div class="mat-elevation-z8"> 
       <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="theader">Order Number</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
         <ng-container matColumnDef="itemOrdered.productName">
           <th mat-header-cell *matHeaderCellDef class="theader th">Product Name</th>
           <td mat-cell *matCellDef="let element" class="th"> {{element.itemOrdered.productName}} </td>
         </ng-container>
         <ng-container matColumnDef="price" >
          <th mat-header-cell *matHeaderCellDef class="theader th" style="padding-left: 20px;">Price</th>
          <td mat-cell *matCellDef="let element" class="th"> {{element.price}} </td>
        </ng-container>
         <ng-container matColumnDef="quantity" >
          <th mat-header-cell *matHeaderCellDef class="theader th" >Quantity</th>
          <td mat-cell *matCellDef="let element" class="th"> {{element.quantity}} </td>
        </ng-container>
         <ng-container matColumnDef="itemOrdered.coffeeOption">
           <th mat-header-cell *matHeaderCellDef class="theader th"> CoffeeOption </th>
           <td mat-cell *matCellDef="let element" class="th"> {{element.itemOrdered.coffeeOption}} </td>
         </ng-container>
         <ng-container matColumnDef="itemOrdered.scale">
          <th mat-header-cell *matHeaderCellDef class="theader th"> Scale </th>
          <td mat-cell *matCellDef="let element" class="th"> {{element.itemOrdered.scale}} </td>
         </ng-container>
         <ng-container matColumnDef="grindName">
          <th mat-header-cell *matHeaderCellDef class="theader th"> Grind Name </th>
          <td mat-cell *matCellDef="let element" class="th"> {{element.grindName}} </td>
         </ng-container>
         <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef class="theader th"> Order Id </th>
          <td mat-cell *matCellDef="let element" class="th"> {{element.orderId}} </td>
         </ng-container>
          <ng-container matColumnDef="edit">
           <th mat-header-cell *matHeaderCellDef > </th>
           <td mat-cell *matCellDef="let element" class="th">
             <a href="/viewOrder/{{element.id}}" > <mat-icon color="primary">edit</mat-icon></a> 
           </td>
         </ng-container>
         <ng-container matColumnDef="delete">
           <th mat-header-cell *matHeaderCellDef ></th>
           <td mat-cell *matCellDef="let element;  let j ;">
             <a href="/viewOrder/{{element.id}}" > <mat-icon color="warn">delete</mat-icon></a> 
           </td>
         </ng-container>
         
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
         
       </table>
       <mat-paginator style="color: orange"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
       aria-label="Select page">
     </mat-paginator>
     </div>
     </div>
    </div>
  </div>