import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IContact } from '../shared/models/contactUs';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
 FormData: UntypedFormGroup;
 successMessage: string;
 errorMessage: string;
 showSuccess: boolean;
 showError: boolean;
 
  errors: string[];
  constructor(private fb: UntypedFormBuilder, private router: Router, private contactService: ContactService) { }

  ngOnInit(): void {
    this.FormData = this.fb.group({
      Name: new UntypedFormControl('', [Validators.required]),
      Email: new UntypedFormControl('', [Validators.compose([Validators.required, Validators.email])]),
      PhoneNumber: new UntypedFormControl('', [Validators.required]),
      Message: new UntypedFormControl('', [Validators.required])
      })
  }
  
  onSubmit(FormData: IContact) {
    this.contactService.contactUs(FormData)
    .subscribe(() => {
      this.showSuccess = true;
      this.successMessage = 'Your message was successfully submitted. We will contact you soon.'
      this.FormData.reset();
    }, error => {
      this.showError = true;
      this.errorMessage  = "Sorry, your message is not submitted. Please get in touch with us over the phone.";
    })
    
  
  }


}
