 <!-- Top bar Start -->
 <div class="top-bar" >
    <div class="container-fluid" style="height: 40px;">
        <div class="row">
            <div class="col-sm-6" style="padding-top: 5px">
                <i class="fa fa-envelope fa-lg" style="padding-top: 8px; color: #fff">&nbsp;&nbsp;&nbsp;support@kirar20.com</i>
               
            </div>
            <div class="col-sm-6" style="padding-top: 5px">
                <i class="fa fa-phone fa-lg" style="padding-top: 8px; color: #fff">&nbsp;&nbsp; (202) 297-4567</i>
            
            </div>
        </div>
    </div>
</div>
<!-- Top bar End -->



