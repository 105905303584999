
<pagination *ngIf="totalCount && pageSize"
[boundaryLinks]="true"
[totalItems]="totalCount"
[itemsPerPage]="pageSize"
[maxSize]="1"
[ngModel]="pageNumber"
(pageChanged)="onPagerChanged($event)"
previousText="&lsaquo;"
nextText="&rsaquo;"
firstText="&laquo;"
lastText="&raquo;">
</pagination>
<!--[maxSize] = 10-->