import { Component, Input, OnInit } from '@angular/core';
import { BasketService } from 'src/app/basket/basket.service';
import { IBasketTotals } from '../../models/basket';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-order-totals',
  templateUrl: './order-totals.component.html',
  styleUrls: ['./order-totals.component.css']
})
export class OrderTotalsComponent {
  //private basketTotalSource = new BehaviorSubject<IBasketTotals | null>(null);
  constructor(public basketService: BasketService) { }
}
