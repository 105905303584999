<div class="d-flex justify-content-center mt-5">
  <div class="col-lg-3">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="text-center mb-4">
              <h1 class="h3 mb-3 font-weight-normal">Login</h1>
          </div>

          <app-text-input formControlName="email" [label]="'Email address'"></app-text-input>
          <app-text-input formControlName="password" [label]="'Password'" [type]="'password'"></app-text-input>

          <button [disabled]="loginForm.invalid" class="btn btn-lg btn warning btn-block" type="submit">Login in</button>
      </form>
      <div>
          <br>
           
        </div>
        <br>
        <br>
  </div>
 
</div>


<br>
<br>