<div class="page-header">
  <div class="container">
      <h1>{{header}}</h1>
  </div>
</div>

<div class="container">
  <div class="row top-row" style="padding-left: 5px;" >
      <div class="col-lg-2 row end-xs">
          <a routerLink="/admindelivery" color="primary" mat-raised-button>
              <mat-icon>chevron_left</mat-icon>
              Go Back
          </a>
      </div>
  </div>
<br>
<ng-container>
  <div class="row">
      <div class="col-lg-6">
          <form #productDetailsForm="ngForm">
           <div class="row">
              <div class="col-xs-12">
                <mat-form-field appearance="outline"  style="width: 400px;padding-left: 5px;">
                  <mat-label>Grind Name: </mat-label>
                  <input type="text" matInput [(ngModel)]="grind.grindName" name="name">
                 </mat-form-field>
              </div>
           </div>
           <div class="row">
                <div class="col-xs-12">
                  <mat-form-field class="example-full-width" appearance="outline"  style="width: 400px;padding-left: 5px;">
                    <mat-label>Stock: </mat-label>
                    <input type="text" matInput [(ngModel)]="grind.stock" name="deliveryTime">
                   </mat-form-field>
                </div>
              </div>
            <div class="row">
              <div class="col-xs-6">
                <div class="row"  *ngIf="!isNewProduct" style="width: 300px;padding-left: 5px;">
                 <div class="col-3"> <button mat-raised-button color="primary" (click)="onUpdate()">Save</button></div>
                <!-- <div class="col-3"><button mat-raised-button color="warn" (click)="onDelete()">Delete</button></div>
                </div>
                <div class="row"  *ngIf="isNewProduct">
                  <div class="col-3"> <button mat-raised-button color="primary" (click)="onAdd()">Add</button></div>-->
                </div>
              </div>
            </div>
          </form>
       </div>
      
  </div>
 </ng-container>

</div>

<!--<div class="page-header">
    <div class="container">
        <h1>{{header}}</h1>
    </div>
  </div>
  
  <div class="container">
    <div class="row top-row" style="padding-left: 5px;" >
        <div class="col-lg-2 row end-xs">
            <a routerLink="/admindelivery" color="primary" mat-raised-button>
                <mat-icon>chevron_left</mat-icon>
                Go Back
            </a>
        </div>
    </div>
  <br>
  <ng-container>
    <div class="row">
        <div class="col-lg-6">
            <form #productDetailsForm="ngForm">
             <div class="row">
                <div class="col-xs-12">
                  <mat-form-field appearance="outline"  style="width: 400px;padding-left: 5px;">
                    <mat-label>Grind Name: </mat-label>
                    <input type="text" matInput [(ngModel)]="delivery.shortName" name="name">
                   </mat-form-field>
                </div>
             </div>
             <div class="row">
                  <div class="col-xs-12">
                    <mat-form-field class="example-full-width" appearance="outline"  style="width: 400px;padding-left: 5px;">
                      <mat-label>Delivery Time: </mat-label>
                      <input type="text" matInput [(ngModel)]="delivery.deliveryTime" name="deliveryTime">
                     </mat-form-field>
                  </div>
                </div>
                <div class="row">
                <div class="col-xs-6">
                    <mat-form-field appearance="outline" style="width: 400px;padding-left: 5px;">
                      <mat-label>Price: </mat-label>
                      <input type="text" matInput [(ngModel)]="delivery.price" name="price">
                     </mat-form-field>
                  </div>
             </div>
             <div class="row">
                <div class="col-xs-6">
                  <mat-form-field appearance="outline"  style="width: 400px;padding-left: 5px;">
                    <mat-label>Delivery Description: </mat-label>
                    <input type="text" matInput [(ngModel)]="delivery.description" name="productBrand">
                   </mat-form-field>
                </div>
             </div>
              
              <div class="row">
                <div class="col-xs-6">
                  <div class="row"  *ngIf="!isNewProduct" style="width: 300px;padding-left: 5px;">
                    <div class="col-3"> <button mat-raised-button color="primary" (click)="onUpdate()">Save</button></div>
                    <div class="col-3"><button mat-raised-button color="warn" (click)="onDelete()">Delete</button></div>
                  </div>
                  <div class="row"  *ngIf="isNewProduct">
                    <div class="col-3"> <button mat-raised-button color="primary" (click)="onAdd()">Add</button></div>
                  </div>
                </div>
              </div>
            </form>
         </div>
        
    </div>
   </ng-container>
  
  </div>-->
  <br>
  <br>
  <br>
  
