import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from 'src/app/orders/orders.service';
import { IOrder } from 'src/app/shared/models/order';
import { AdminService } from '../admin.service';
//import { IOrderToRetrun } from 'src/app/shared/models/orderToReturn';

@Component({
  selector: 'app-user-order',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.css']
})
export class UserOrderComponent implements OnInit {
  orders: IOrder[];
  filterString = '';
  displayedColumns: string[] = ['id','buyerEmail','orderDate','shipToAddress.city','shipToAddress.firstName','shipToAddress.lastName','shipToAddress.phoneNumber','edit','delete'];
  dataSource: MatTableDataSource<IOrder> = new MatTableDataSource<IOrder>();

 @ViewChild(MatPaginator) matPaginator: MatPaginator;
 @ViewChild(MatSort) sort: MatSort;
  constructor(private http: HttpClient, public service : AdminService, 
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.GetProduct();
  }
 /* getOrders() {
    this.service.getOrdersForUser().subscribe((orders: IAdminOrder[]) => {
      this.orders = orders;
    }, error => {
      console.log(error);
    })
  }*/
  GetProduct() {
    this.service.getOrders()
    //debugger;
    //this.service.getOrderItems()
    .subscribe(
      (successResponse: any) => {
         this.orders = successResponse;
         debugger;
       this.dataSource = new MatTableDataSource<IOrder>(this.orders);
        if (this.matPaginator) {
          this.dataSource.paginator = this.matPaginator;
        }
      },
      (errorResponse) => {
        console.log(errorResponse)
      });
    }
    filterProducts() {
    this.dataSource.filter = this.filterString.trim().toLowerCase();
   }

   public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

}
