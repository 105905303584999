import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IDeliveryMethod } from 'src/app/shared/models/deliveryMethod';
import { AdminService } from '../admin.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { IGrind } from 'src/app/shared/models/grind';

@Component({
  selector: 'app-admin-delivery',
  templateUrl: './admin-delivery.component.html',
  styleUrls: ['./admin-delivery.component.css']
})
export class AdminDeliveryComponent {
  grind: IGrind[];
  filterString = '';
  displayedColumns: string[] = ['id','grindName','stock','edit','delete'];
  dataSource: MatTableDataSource<IGrind> = new MatTableDataSource<IGrind>();

 @ViewChild(MatPaginator) matPaginator: MatPaginator;
 @ViewChild(MatSort) sort: MatSort;

 constructor(private http: HttpClient, public service : AdminService, 
  private route: ActivatedRoute, private router: Router) { } 

ngOnInit(): void {
  this.GetGrind();
}
GetGrind() {
  this.service.getGrind()
  .subscribe({
    next: (Response: any) => {
      console.log(Response)
       this.grind = Response;
       this.dataSource = new MatTableDataSource<IGrind>(this.grind)
    },
    error: (err: HttpErrorResponse) => {
     // this. = true;
      //this.errorMessage = err.message;
    }
    });
  
  }
 /* GetDelivery() {
    this.service.getDeliveryMethod()
    .subscribe({
      next: (Response: any) => {
        console.log(Response)
         this.delivery = Response;
         this.dataSource = new MatTableDataSource<IDeliveryMethod>(this.delivery)
      },
      error: (err: HttpErrorResponse) => {
       
      }
      });
    
    }*/
 
}
