import { Component } from '@angular/core';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDeliveryMethod } from 'src/app/shared/models/deliveryMethod';
import { IGrind } from 'src/app/shared/models/grind';

@Component({
  selector: 'app-update-delivery',
  templateUrl: './update-delivery.component.html',
  styleUrls: ['./update-delivery.component.css']
})
export class UpdateDeliveryComponent {
  grindId: string | null | undefined;
  grind: IGrind = {
    id: 0,
    grindName: '',
    stock: 0,
  }
  isNewProduct = false;
   header = '';
  // displayProfileImageUrl = '';
   currentuser: string;
  constructor(private service : AdminService, private readonly route: ActivatedRoute, 
    private snackbar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (params) => {
        debugger;
        this.grindId = params.get('id');
       // this.currentUser$ = this.service.currentUser$;
        if (this.grindId) {
          if(this.grindId.toLowerCase() == 'Add'.toLowerCase()){
            this.isNewProduct = true;
            this.header = "Add New product";
          } else {
            this.isNewProduct = false;
            this.header = "Edit product";
            this.service.getGrindById(this.grindId)
            .subscribe(
              (successResponse) => {
                this.grind = successResponse;
              },
              (errorResponse) => {
                console.log(errorResponse)
              }
            );
          }
         
        }

      }
    );
  }
  onUpdate(): void {
    this.service.updateGrind(this.grindId, this.grind)
    .subscribe(
     (successResponse) => {
       this.snackbar.open('Product updated successfully', undefined, {duration: 2000});
       setTimeout(() => {
         this.router.navigateByUrl('adminProduct01102023');
       }, 2000);
     },
     (errorResponse) => {
      console.log(errorResponse)
     }
  );
   }
 /* onDelete(): void {
    if (confirm("Are you sure to delete "+this.grind.grindName)) {
     this.service.deleteDelivery(this.grindId)
     .subscribe(
      (successResponse) => {
        console.log(successResponse);
       this.snackbar.open('Product deleted successfully', undefined, {
        duration: 2000
       });
       setTimeout(() => {
        this.router.navigateByUrl('admindelivery');
      }, 2000);
      },
      (errorResponse) => {
        console.log(errorResponse)
      } );
    }
   }*/
  /* onAdd(): void {
     this.service.addDeliveryMethod(this.delivery)
     .subscribe(
      (successResponse) => {
        this.snackbar.open('Product Added successfully', undefined, {
          duration: 2000
         });
         setTimeout(() => {
          this.router.navigateByUrl('adminProduct01102023');
         // this.router.navigateByUrl(`admin/${successResponse.id}`);
        }, 2000);
      },
      (errorResponse) => {
        console.log(errorResponse)
      }
     )
   }*/
   /*uploadImage(event: any): void {
    if(this.deliveryId) { 
      const file: File = event.target.files[0];
      this.service.uploadImage(this.deliveryId, file)
      .subscribe(
        (successResponse) => {
          this.delivery.pictureUrl = successResponse;
          this.setImage();
         // this.snackbar.open('Profile Image Updated', undefined, {duration: 2000});
         this.router.navigateByUrl('adminProduct01102023');
        },
        (errorResponse) => {
          console.log(errorResponse)
        }
      )
    }
   }*/
   /* private setImage(): void {
         if (this.delivery.pictureUrl) {
           this.displayProfileImageUrl = this.service.getImagePath(this.delivery.pictureUrl);
         }else {
            this.displayProfileImageUrl = '/assets/images/resources/spices3.jpg';
         }
      
    }*/
   /* reloadPage() {
      setTimeout(()=>{
        window.location.reload();
      }, 100);

    }*/
}
