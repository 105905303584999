<div class="container mt-2">
  <div class="row top-row">
    <div class="col-lg-2 row end-xs" >
        <a routerLink="/adminProduct01102023" color="primary" mat-raised-button>
            <mat-icon>chevron_left</mat-icon>
            Go Back
        </a>
    </div>
</div>
<br>
    <div class="col-6">
    <div class="row end-xs mt-1">
      
     <div class="col-xs-12">
      <input type="text" class="search-input" placeholder="Search products" 
      [(ngModel)] ="filterString"
      (keyup)="filterProducts()">
     </div>
    </div>
    <br>
   
    <div class="row">
      <div class="col-lg-9" style="padding-left: 10px">
        <div class="row">
          <div class="mat-elevation-z8"> 
           <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="id"  style="padding-left: 60px;">
                <th mat-header-cell *matHeaderCellDef class="theader" style="padding-left: 60px;">Id</th>
                <td mat-cell *matCellDef="let element" style="padding-left: 50px;"> {{element.id}} </td>
              </ng-container>
             <ng-container matColumnDef="name">
               <th mat-header-cell *matHeaderCellDef class="theader" style="padding-left: 60px;">Name</th>
               <td mat-cell *matCellDef="let element" style="padding-left: 50px;"> {{element.name}} </td>
             </ng-container>
             <ng-container matColumnDef="edit" >
              <th mat-header-cell *matHeaderCellDef > </th>
              <td mat-cell *matCellDef="let element" class="th" style="padding-left: 60px;">
                <a href="/updateproductbrand/{{element.id}}" > <mat-icon color="primary">edit</mat-icon></a> 
              </td>
            </ng-container>
             <ng-container matColumnDef="delete"  style="padding-left: 40px;">
               <th mat-header-cell *matHeaderCellDef style="padding-left: 50px;"></th>
               <td mat-cell *matCellDef="let element;  let j ;">
                  <mat-icon color="warn"  (click)="onDelete(element.id)" style="padding-left: 60px;">delete</mat-icon>
               </td>
             </ng-container>
              <td style="padding-left: 40px;"></td>
             <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           
           </table>
           <mat-paginator style="color: orange"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
           aria-label="Select page">
         </mat-paginator>
         </div>
         </div>
      </div>
    </div>
   
  </div>
  </div>
  
  <br>
  <br>

