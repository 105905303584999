import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAminUser } from '../shared/models/adminUser';
import { IBrand } from '../shared/models/brand';
import { Pagination } from '../shared/models/pagination';
import { Product } from '../shared/models/product';
import { IProductAdmin } from '../shared/models/productadmin';
import { IProductToCreate } from '../shared/models/productToCreate';
import { IType } from '../shared/models/productType';
import { ShopParams } from '../shared/models/shopParams';
import { IDeliveryMethod } from '../shared/models/deliveryMethod';
import { IDeliveryToCreate } from '../shared/models/deliveryToCreate';
import { Productvariance } from '../shared/models/productvariance';
import { IGrind } from '../shared/models/grind';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private currentUserSource = new ReplaySubject<IAminUser>(1);
  currentUser$ = this.currentUserSource.asObservable();
  baseUrl = environment.apiUrl;
  products: IProductAdmin[] = [];
  brands: IBrand[] = [];
  types: IType[] = [];
  pagination = new Pagination();
  shopParams = new ShopParams();
  productCache = new Map();
  variance: Productvariance[];
  constructor(private http: HttpClient, private router: Router) { }

  public getProducts() : Observable<IProductAdmin[]> {
    return this.http.get<IProductAdmin[]>(this.baseUrl +"admin/GetProducts");
  }
  getProductById(productId: string): Observable<IProductAdmin> {
    return this.http.get<IProductAdmin>(this.baseUrl +"Admin?productId=" + productId)
  }
  getBrands() {
    if (this.brands.length > 0) {
      return of(this.brands);
    }
    return this.http.get<IBrand[]>(this.baseUrl + 'admin/brands').pipe(
      map(response => {
        this.brands = response;
        return response;
      })
    )
  }
  getBrandswithId(brandid: string):  Observable<IBrand>  {
   return this.http.get<IBrand>(this.baseUrl + 'admin/brandid?brandid=' + brandid)
  }
  updateBrand(id: number, updatebrandRequest: IBrand):  Observable<IBrand>  {
    return this.http.put<IBrand>(this.baseUrl + 'admin/ProductBrandid?id=' + id, updatebrandRequest)
  }
  getTypes() {
    if (this.types.length > 0) {
      return of(this.types);
    }
    return this.http.get<IType[]>(this.baseUrl + 'admin/types').pipe(
      map(response => {
        this.types = response;
        return response;
      })
    )
  }
  updateProduct(productId: string, productRequest: IProductAdmin): Observable<IProductAdmin> 
  {
      const  updateProduct: IProductToCreate = {
      name: productRequest.name,
      description: productRequest.description,
      price: productRequest.price,
      scale: productRequest.scale,
      pictureUrl: productRequest.pictureUrl,
      productType: productRequest.productType,
      productBrand: productRequest.productBrand,
      roastingOption: productRequest.roastingOption,
      stock: productRequest.stock
    };
    return this.http.put<IProductAdmin>(this.baseUrl +"admin/" + productId, updateProduct);
  }
  deleteProduct(productId: any): Observable<IProductAdmin> {
    return this.http.delete<IProductAdmin>(this.baseUrl +"admin/DeleteProduct?productId=" + productId);
  }

  addproduct(productRequest: IProductAdmin): Observable<IProductAdmin>  {
    const  addProductModel: IProductToCreate = {
      name: productRequest.name,
      description: productRequest.description,
      price: productRequest.price,
      scale: productRequest.scale,
      pictureUrl: productRequest.pictureUrl,
      productType: productRequest.productType,
      productBrand: productRequest.productBrand,
      roastingOption: productRequest.roastingOption,
      stock: productRequest.stock
    };
    return this.http.post<IProductAdmin>(this.baseUrl +"admin/admin/add", addProductModel)
  }
  uploadImage(productId: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append("profileImage", file);

    return this.http.post(this.baseUrl + 'admin/admin/' + productId + '/upload-image', 
        formData, {
          responseType: 'text'
        });
  }
   getImagePath(relativePath: string) {
    return `${relativePath}`;
   }
   BookDelete (productId:String):Observable<string>{
    return this.http.delete<string>(this.baseUrl+"admin/DeleteProduct?productId="+productId);
  }
  productbrandDelete (productId:String):Observable<string>{
    return this.http.delete<string>(this.baseUrl+"admin/DeleteBrand?productId="+productId);
  }
  getOrders() {
    return this.http.get(this.baseUrl + 'Admin/GetOrder');
  }
  getOrderItems() {
    return this.http.get(this.baseUrl + 'Admin/GetOrderItem');
  }
  getOrderDetailed(id: number) {
    return this.http.get(this.baseUrl + 'admin/' + id);
  }
  getRoastingoptionById(id: any): Observable<Productvariance> {
    return this.http.get<Productvariance>(this.baseUrl +"Admin/roastingoptionid?id=" + id)
  }
   getRoastingoption() {
    return this.http.get<Productvariance[]>(this.baseUrl + 'Admin/SelectWeightPrice');
  }
  updateRoastingOption(id: number, roastingRequest: Productvariance): Observable<Productvariance> 
  {
    return this.http.put<Productvariance>(this.baseUrl +"admin/Roastingid?id="+ id, roastingRequest);
  }
  addRoastOption(productRequest: Productvariance): Observable<Productvariance>  {
    return this.http.post<Productvariance>(this.baseUrl +"admin/addroasting", productRequest)
  }
  deleteRoasting(id: any): Observable<Productvariance> {
    return this.http.delete<Productvariance>(this.baseUrl +"admin/deleteRoasting?id=" + id);
  }
  getDeliveryMethod() {
    return this.http.get(this.baseUrl + 'Admin/deliveryMethods');
  }
  getGrindById(grindId: any): Observable<IGrind> {
    return this.http.get<IGrind>(this.baseUrl +"ProductCoffee/grindId?grindId=" + grindId);
  }
  /*deleteDelivery(deliveryId: any): Observable<IDeliveryMethod> {
    return this.http.delete<IDeliveryMethod>(this.baseUrl +"AdminDeliveryMethod/DeleteDelivery?deliveryId=" + deliveryId);
  }*/
  getGrind() {
    debugger;
    return this.http.get<IGrind[]>(this.baseUrl + 'ProductCoffee/grind');
  }
  /*addDeliveryMethod(deliveryRequest: IDeliveryMethod): Observable<IDeliveryMethod> {
    const  addDeliveryModel: IDeliveryToCreate = {
      shortName: deliveryRequest.shortName,
      description: deliveryRequest.description,
      price: deliveryRequest.price,
      deliveryTime: deliveryRequest.deliveryTime
    };
    return this.http.post<IDeliveryMethod>(this.baseUrl + 'AdminDeliveryMethod/AddDeliveryMethod',addDeliveryModel);
  }*/
  updateGrind(grindId: string, grindRequest: IGrind): Observable<IGrind>
  {
      const  grindProduct: IGrind = {
        id: grindRequest.id,
        grindName: grindRequest.grindName,
        stock: grindRequest.stock,
      
    };
    return this.http.put<IGrind>(this.baseUrl +"ProductCoffee/grindId?grindId=" + grindId, grindProduct);
  }
  login(values: any) {
    return this.http.post(this.baseUrl + 'admin/getadminuser', values).pipe(
      map((user: IAminUser) => {
        if (user) {
          localStorage.setItem('token', user.token);
          this.currentUserSource.next(user);
        }
      })
    )
  }
  getCurrentUserValue() {
    return this.currentUserSource;
  }

  loadCurrentUser(token: string) {
    if (token === null) {
      this.currentUserSource.next(null);
      return of(null);
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.get(this.baseUrl + 'admin', {headers}).pipe(
      map((user: IAminUser) => {
        if (user) {
          //localStorage.setItem('token', user.token);
          this.currentUserSource.next(user);
        }
      })
    )
  }
  register(values: any) {
    return this.http.post(this.baseUrl + 'admin/Createuser', values).pipe(
      map((user: IAminUser) => {
        if (user) {
          //localStorage.setItem('token', user.token);
          this.currentUserSource.next(user);
        }
      })
    )
  }
 
}
