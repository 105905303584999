<div id="content" style="padding-bottom: 30px"> <!-- Content -->
    <div class="page-title">
        <div class="container">
            <div class="page-title-inner">
               <!-- <h3>Contact us</h3>-->
                <div class="breadcumb"> <a href="./">Home</a><span> / </span><span>Contact us</span></div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    
    <div class="about-page">
        <div class="container">
            <div class="row">
                <div class="col-md-6 ct-desc">
                    <h3>kirar</h3>
                  
                    <p>A kirar is a traditional East African string instrument. It is revered for its simplicity and unique spectrum of different musical tones. Despite the instrument’s anonymity, many legendary artists have expressed great admiration for its sweet notes and smooth melodies</p>
                    <h3 class="contact-loc">Online store contact</h3>
                    <div class="row loc-det">
                        <div class="col-md-4">
                            <p>
                                Phone: (202) 297-4567<br/>
                                Email: orderkirar@gmail.com<br/>
                                Address:<br/>
                                1932 9th street NW<br/>
                                Washington, D.C 20001<br/>
                               
                            
                            </p>
                        </div>
                        <div class="col-md-4">
                            <p>
                                Phone: +251944067663<br/>
                                Address: Addis Ababa, Ethiopia<br/>
                              
                            
                            </p>
                        </div>
                        <div class="col-md-4">
                            <p>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-1">
                </div>
                <div class="col-md-5 ct-form">
                    <h3>Contact Form</h3>
                    <div *ngIf="showError" class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                    <div *ngIf="showSuccess" class="alert alert-success" role="alert">
                        {{successMessage}}
                    </div>	
                    <form [formGroup]="FormData"  (ngSubmit)="onSubmit(FormData.value)" >
                        <div class="form-group">
                            <label for="name">Your Name*</label>
                            <input type="text" class="form-control" #propname name="Name" formControlName="Name" id="name" placeholder="Name" required>
                            <span *ngIf="!propname.valid && propname.touched" class="error-block">Please Enter your name</span>
                        </div>

                        <div class="form-group">
                            <label for="email">Your E-mail*</label>
                            <input type="email" class="form-control" #propemail name="Email" formControlName="Email"  id="email" placeholder="E-mail" required>
                            <span *ngIf="!propemail.valid && propemail.touched" class="error-block">Please Enter your email </span>
                        </div>

                       <div class="form-group">
                            <label for="phoneNumber">Phone Number*</label>
                            <input type="text" class="form-control" #proppho name="PhoneNumber" formControlName="PhoneNumber" id="phoneNumber"  placeholder="Phone Number" required>
                            <span *ngIf="!proppho.valid && proppho.touched" class="error-block">Please Enter Phone Number</span>
                        </div>

                        <div class="form-group">
                            <label for="message">Message*</label>
                            <textarea class="form-control" name="Message" formControlName="Message"  #propmessage id="message" required></textarea>
                            <span *ngIf="!propmessage.valid && propmessage.touched" class="error-block">Please Enter your message</span>
                        </div>
                        <button type="submit" [disabled]="!FormData.valid" class="btn btn-warning">Submit</button>
                    </form>
                </div>
            </div>
            
          
        </div>
    </div>

</div> <!-- Content -->

<br>
<br>
