import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IBrand } from 'src/app/shared/models/brand';
import { Product } from 'src/app/shared/models/product';
import { IType } from 'src/app/shared/models/productType';
import { ShopParams } from 'src/app/shared/models/shopParams';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-product',
  templateUrl: './admin-product.component.html',
  styleUrls: ['./admin-product.component.css']
})
export class AdminProductComponent implements OnInit {
  adminProduct01102023: false;
  productId: string | null | undefined;
  products: Product[];
  brands: IBrand[];
  types: IType[];
  shopParams: ShopParams;
  filterString = '';
  returnUrl: string;

  displayedColumns: string[] = ['id','name', 'scale', 'price','edit','delete'];
  dataSource: MatTableDataSource<Product> = new MatTableDataSource<Product>();

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  constructor(private http: HttpClient, public service : AdminService, 
    private route: ActivatedRoute, private snackbar: MatSnackBar,private router: Router) { }

  ngOnInit(): void {
    this.GetProduct();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/admin';
  }
  GetProduct() {
    this.service.getProducts()
    .subscribe(
      (successResponse) => {
         this.products = successResponse;
         this.dataSource = new MatTableDataSource<Product>(this.products);
        if (this.matPaginator) {
          this.dataSource.paginator = this.matPaginator;
        }
      },
      (errorResponse) => {
        console.log(errorResponse)
      });
    }
    filterProducts() {
    this.dataSource.filter = this.filterString.trim().toLowerCase();
   }

   getBrands() {
    this.service.getBrands().subscribe(response => {
      this.brands = [{id: 0, name: 'All'}, ...response];
    }, error => {
      console.log(error);
    })
  }

  getTypes() {
    this.service.getTypes().subscribe(response => {
      this.types = [{id: 0, name: 'All'}, ...response];
    }, error => {
      console.log(error);
    })
  }
  logout() {
    this.router.navigateByUrl(this.returnUrl)
  }

}
