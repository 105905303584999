import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Product } from 'src/app/shared/models/product';
import { IProductToCreate } from 'src/app/shared/models/productToCreate';
import { environment } from 'src/environments/environment';
import { AdminService } from '../admin.service';
import {MatTableDataSource} from '@angular/material/table';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { DataSource } from '@angular/cdk/table';
import { MatTableModule } from '@angular/material/table';
import { IBrand } from 'src/app/shared/models/brand';
import { IType } from 'src/app/shared/models/productType';
import { ShopParams } from 'src/app/shared/models/shopParams';
import { ShopService } from 'src/app/shop/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
//import { IUpdateProductModel } from 'src/app/shared/models/updateProductModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IProductAdmin } from 'src/app/shared/models/productadmin';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  adminProduct01102023 = false;
  returnUrl: string;
  loginForm: UntypedFormGroup; 

  constructor(private http: HttpClient, public service : AdminService, 
    private route: ActivatedRoute, private snackbar: MatSnackBar, private router: Router) { }
 
   ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/adminProduct01102023';
    this.createLoginForm();
    }
    createLoginForm() {
      this.loginForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, Validators
          .pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')]),
        password: new UntypedFormControl('', Validators.required),
      })
    }
    onSubmit() {
      this.service.login(this.loginForm.value).subscribe(() => {
       // console.log();
        this.router.navigateByUrl(this.returnUrl)
      }, error => {
        console.log(error);
      })
    }
   
}
