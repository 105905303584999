<div class="container mt-2">
<div class="row top-row">
    <div class="col-lg-2 row end-xs" >
        <a routerLink="/adminProductBrand" color="primary" mat-raised-button>
            <mat-icon>chevron_left</mat-icon>
            Go Back
        </a>
    </div>
</div>
<div class="container my-5">
    <h1 class="mb-3">Edit Product Brand</h1>
    <div class="row">
        <div class="col-6">
            <form #form="ngForm" (ngSubmit)="updateBrand()">
                <div class="mb-3">
                    <label for="name" class="form-label">Id</label>
                    <input type="text" class="form-control" [(ngModel)]="branddetail.id" id="id" readonly 
                    name="id">
                </div>
                <div class="mb-3">
                    <label for="name" class="form-label">Name</label>
                    <input type="text" class="form-control" [(ngModel)]="branddetail.name" id="name" name="name">
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
            </form>
        </div>

    </div>
 </div>
</div>
