import { Component, OnInit } from '@angular/core';
import { AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { of, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-admin-signin',
  templateUrl: './admin-signin.component.html',
  styleUrls: ['./admin-signin.component.css']
})
export class AdminSigninComponent implements OnInit {
  registerForm: UntypedFormGroup;
  errors: string[];

  constructor(private fb: UntypedFormBuilder, private service : AdminService,private router: Router) { }

  ngOnInit(): void {
    this.createRegisterForm();
    
  }
  createRegisterForm() {
    this.registerForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, 
        [Validators.required, Validators
        .pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')],
       // [this.validateEmailNotTaken()]
      ],
      password: [null, Validators.required]
    });
  }
  onSubmit() {
    this.service.register(this.registerForm.value).subscribe(response => {
      this.router.navigateByUrl('/adminProduct01102023');
    }, error => {
      console.log(error);
      this.errors = error.errors;
    })
  }
  validateEmailNotTaken(): AsyncValidatorFn {
    return control => {
      return timer(500).pipe(
        switchMap(() => {
          if (!control.value) {
            return of(null);
          }
         // return this.accountService.checkEmailExists(control.value).pipe(
            //map(res => {
              // return res ? {emailExists: true} : null;
            //})
          //);
        })
      );
    };
  }

}
