import { Component } from '@angular/core';
import { AdminService } from '../admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Productvariance } from 'src/app/shared/models/productvariance';
//import { IRoastingOption } from 'src/app/shared/models/roastOption';

@Component({
  selector: 'app-view-roastoption',
  templateUrl: './view-roastoption.component.html',
  styleUrls: ['./view-roastoption.component.css']
})
export class ViewRoastoptionComponent {
  roastingId: string | null | undefined;
  variance: Productvariance = {
    id: 0,
    weightCoffee: '',
    priceCoffee: 0.00,
  }
  isNewProduct = false;
   header = '';
   currentuser: string;
   constructor(private service : AdminService, private readonly route: ActivatedRoute, 
    private snackbar: MatSnackBar, private router: Router) { }
    ngOnInit(): void {
      this.route.paramMap.subscribe(
        (params) => {
           this.roastingId = params.get('id');
         // this.currentUser$ = this.service.currentUser$;
          if (this.roastingId) {
            if(this.roastingId.toLowerCase() == 'Add'.toLowerCase()){
              this.isNewProduct = true;
              this.header = "Add New product";
            } else {
              this.isNewProduct = false;
              this.header = "Edit product";
              this.service.getRoastingoptionById(this.roastingId)
              .subscribe({next: (successResponse) =>
                  this.variance = successResponse,
                error:errorResponse => 
                  console.log(errorResponse)
                
            });
            }
           
          }
  
        }
      );
    }
    onUpdate(): void {
      console.log("Id",this.variance.id)
      this.service.updateRoastingOption(this.variance.id, this.variance)
      .subscribe({next:successResponse => {
         this.snackbar.open('Product updated successfully', undefined, {duration: 2000});
         setTimeout(() => {
          this.router.navigateByUrl('roastingoption');
        }, 2000);
       },
       error: error => {
        console.log(error)
       }
    });
  }
  onAdd(): void {
    this.service.addRoastOption(this.variance)
    .subscribe({next:
     (successResponse) => {
       this.snackbar.open('Product Added successfully', undefined, {
         duration: 2000
        });
        setTimeout(() => {
         this.router.navigateByUrl('roastingoption');
       }, 2000);
     },
     error: (errorResponse) => {
       console.log(errorResponse)
     }
  })
  }
  onDelete(): void {
    if (confirm("Are you sure to delete "+this.variance.weightCoffee)) {
     this.service.deleteRoasting(this.roastingId)
     .subscribe({next:
      (successResponse) => {
        console.log(successResponse);
       this.snackbar.open('Product deleted successfully', undefined, {
        duration: 2000
       });
       setTimeout(() => {
        this.router.navigateByUrl('roastingoption');
      }, 2000);
      },
      error: (errorResponse) => {
        console.log(errorResponse)
      }
    });
    }
  
  
   }
}
