<div class="container" style="padding-top: 30px">
    <div class="row">
        <div class="col-lg-6"> 
            <div class="card mb-3" style="max-width: 540px;">
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="./assets/wwwroot/img/coffee2.jpg" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h3 class="card-title">Kirar Tea Spices</h3>
                    <p class="card-text">Our tea spices comprise of hand-picked organic cinnamon, cloves and cardamom, as they all give a sweet and natural flavor.Cardamon spice is harvested in warm climates and has its benefits in easing digestion, oral health, blood pressure and the respiratory system.Cinnamon spice is obtained from the inner bark of trees, it provides multiple health benefits such as weight loss, boost in metabolism, improves brain function, and fights inflammation in body.Clove spice is an aromatic flower bud that brings many nutritional benefits such as antioxidants, immunity boost, help combat toothache and sore gums, anti-inflammatory, healing sore throats, cold, cough and headaches.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
         <div class="col-lg-6">
             <div class="card mb-3" style="max-width: 540px;">
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="assets/wwwroot/img/coffee1.png" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h3 class="card-title">Kirar Coffee</h3>
                    <p class="card-text">Since 2019, Kirar has been crafting the finest coffee by carefully selecting and hand roasting the best organic coffee beans.  At Kirar, exceptional ingredients are only the first step. What sets us apart from the pack is our heartfelt dedication to producing coffee that not only tastes amazing but is responsibly sourced. We guarantee you a coffee experience like no other.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                  </div>
                </div>
              </div>
            </div>
         </div>
    </div>
     <div class="row" style="padding-top: 30px; padding-bottom: 30px">
        <div class="col-lg-6"> 
            <div class="card mb-3" style="max-width: 540px;">
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="assets/wwwroot/img/Honey.png" style="height: 300px;width: 250px" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h3 class="card-title">Kirar Organic Honey</h3>
                    <p class="card-text">Our organic honey is from one of the biggest natural resource of honey production in Ethiopia. Due to its varied ecological and climate conditions, the nation has some of the most diverse flora and fauna worldwide. Beekeeping is also a well-established household activity in many parts of the country. The benefits of honey are endless, it prevents cancer and heart disease, reduces ulcers, helps ease digestive problems, regulates blood sugar, soothes coughs and sore throats.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
         <div class="col-lg-6">
             <div class="card mb-3" style="max-width: 540px;">
              <div class="row g-0">
                <div class="col-md-4">
                  <img src="assets/wwwroot/img/ginger.png" style="height: 300px; width: 170px" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h3 class="card-title">Kirar Ginger</h3>
                    <p class="card-text">Ginger has a long and rich history in tradition and alternative medicine.It’s been used to aid digestion, reduce nausea,boosts immunity,improve stomach performance,reduce inflammation,fight respiratory problems,improve blood circulation,relieves stress and help fight the flu and common cold.It can be blended into smoothies or boiled into tea.It’s an essential in everyone’s kitchen.</p>
                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                  </div>
                </div>
              </div>
            </div>
         </div>
    </div>
</div>
