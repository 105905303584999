<div class="container">
  <div class="row" >
    <div class="col-lg-2 row end-xs" >
      <a routerLink="/adminProductBrand" color="primary" mat-raised-button>
          <mat-icon>chevron_left</mat-icon>
          Go Back
      </a>
  </div>
  <div>
    <ul>
      <li><a class="active" href="/updatedelivery/add" color="primary" mat-raised-button><mat-icon></mat-icon>Add Grind</a></li>
    </ul>
  </div>
  </div>
      <div class="d-flex justify-content-center mt-5">
    <div class="row ml-1">
        <div class="mat-elevation-z8"> 
         <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="theader">Grind Id</th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
           <ng-container matColumnDef="grindName">
             <th mat-header-cell *matHeaderCellDef class="theader th">Grind Name</th>
             <td mat-cell *matCellDef="let element" class="th"> {{element.grindName}} </td>
           </ng-container>
           <ng-container matColumnDef="stock" >
            <th mat-header-cell *matHeaderCellDef class="theader th" >Stock</th>
            <td mat-cell *matCellDef="let element" class="th"> {{element.stock}} </td>
          </ng-container>
            <ng-container matColumnDef="edit">
             <th mat-header-cell *matHeaderCellDef > </th>
             <td mat-cell *matCellDef="let element" class="th">
               <a href="/updatedelivery/{{element.id}}" > <mat-icon color="primary">edit</mat-icon></a> 
             </td>
           </ng-container>
           <ng-container matColumnDef="delete">
             <th mat-header-cell *matHeaderCellDef ></th>
             <td mat-cell *matCellDef="let element;  let j ;">
               <a href="/updatedelivery/{{element.id}}" > <mat-icon color="warn">delete</mat-icon></a> 
             </td>
           </ng-container>
           
           <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
           <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           
         </table>
       </div>
       </div>
      </div>
<!--<div class="container">
  <div class="row" >
    <div class="col-lg-2 row end-xs" >
      <a routerLink="/adminProductBrand" color="primary" mat-raised-button>
          <mat-icon>chevron_left</mat-icon>
          Go Back
      </a>
  </div>
  <div>
    <ul>
      <li><a class="active" href="/updatedelivery/add" color="primary" mat-raised-button><mat-icon></mat-icon>Add Dlivery</a></li>
    </ul>
  </div>
  </div>
      <div class="d-flex justify-content-center mt-5">
    <div class="row ml-1">
        <div class="mat-elevation-z8"> 
         <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="theader">Delivery Id</th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
           <ng-container matColumnDef="shortName">
             <th mat-header-cell *matHeaderCellDef class="theader th">shortName</th>
             <td mat-cell *matCellDef="let element" class="th"> {{element.shortName}} </td>
           </ng-container>
           <ng-container matColumnDef="deliveryTime" >
            <th mat-header-cell *matHeaderCellDef class="theader th" >Delivery Time</th>
            <td mat-cell *matCellDef="let element" class="th"> {{element.deliveryTime}} </td>
          </ng-container>
          <ng-container matColumnDef="description" >
            <th mat-header-cell *matHeaderCellDef class="theader th" style="padding-left: 20px;">Description</th>
            <td mat-cell *matCellDef="let element" class="th"> {{element.description}} </td>
          </ng-container>
          <ng-container matColumnDef="price" >
            <th mat-header-cell *matHeaderCellDef class="theader th" style="padding-left: 20px;">Price</th>
            <td mat-cell *matCellDef="let element" class="th"> {{element.price}} </td>
          </ng-container>
            <ng-container matColumnDef="edit">
             <th mat-header-cell *matHeaderCellDef > </th>
             <td mat-cell *matCellDef="let element" class="th">
               <a href="/updatedelivery/{{element.id}}" > <mat-icon color="primary">edit</mat-icon></a> 
             </td>
           </ng-container>
           <ng-container matColumnDef="delete">
             <th mat-header-cell *matHeaderCellDef ></th>
             <td mat-cell *matCellDef="let element;  let j ;">
               <a href="/updatedelivery/{{element.id}}" > <mat-icon color="warn">delete</mat-icon></a> 
             </td>
           </ng-container>
           
           <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
           <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           
         </table>
       </div>
       </div>
      </div>-->
      <br>
