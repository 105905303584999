
    <div class="container">
       
        <div class="row top-row">
            <div class="col-lg-2 row end-xs" >
                <a routerLink="/adminProduct01102023" color="primary" mat-raised-button>
                    <mat-icon>chevron_left</mat-icon>
                    Go Back
                </a>
            </div>
        </div>
      <h1>Register</h1>
      <p>Please fill in this form to create an account.</p>
      <hr>
      <div class="d-flex justify-content-center mt-5">
        <div class="col-3">
    <h2>Register</h2>
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" formControlName="firstName" class="form-control"  />
        </div>
        <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" formControlName="lastName" class="form-control"  />
        </div>
        <div class="form-group">
            <label for="username">Email</label>
            <input type="text" formControlName="email" class="form-control" />
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" formControlName="password" class="form-control"  />
        </div>
        <div class="form-group">
            <button  class="btn btn-lg btn warning btn-block" type="submit">Register</button>
            <a routerLink="/adminProduct01102023" class="btn btn-link">Cancel</a>
        </div>
    </form>
        </div>
    </div>
    </div>
    
    <div class="container signin">
      
    </div>
 
