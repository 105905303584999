import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAddress } from '../shared/models/address';
import { IDiffeShippingAddress } from '../shared/models/deffShippingAddress';
import { ForgotPassword } from '../shared/models/forgotPassword';
import { ResetPasswordDto } from '../shared/models/resetPasswordDto';
import { IUser } from '../shared/models/user';
import { IBillingDetail } from '../shared/models/billingDetail';
//import { BillingDetail } from '../shared/models/billingDetail';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseUrl = environment.apiUrl;
  private currentUserSource = new ReplaySubject<IUser | null>(1);
  currentUser$ = this.currentUserSource.asObservable();

  private isAdminSource = new ReplaySubject<boolean>(1);
  isAdmin$ = this.isAdminSource.asObservable();
  constructor(private http: HttpClient, private router: Router) { }
  
  //getCurrentUserValue() {
    //return this.currentUserSource;
 // }

  loadCurrentUser(token: string) {
    if (token === null) {
      this.currentUserSource.next(null);
      return of(null);
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.get<IUser>(this.baseUrl + 'account', {headers}).pipe(
      map(user => {
        if (user) {
          localStorage.setItem('token', user.token);
          this.currentUserSource.next(user);
          return user;
        } else {
          return null;
        }
      })
    )
  }
  login(values: any) {
    return this.http.post<IUser>(this.baseUrl + 'account/login', values).pipe(
      map(user => {
        localStorage.setItem('token', user.token);
        this.currentUserSource.next(user);
      })
    )
  }
  register(values: any) {
    return this.http.post<IUser>(this.baseUrl + 'account/register', values).pipe(
      map(user => {
        localStorage.setItem('token', user.token);
        this.currentUserSource.next(user);
      })
    )
  }
  logout() {
    localStorage.removeItem('token');
    this.currentUserSource.next(null);
    this.router.navigateByUrl('/');
  }
  checkEmailExists(email: string) {
    return this.http.get<boolean>(this.baseUrl + 'account/emailexists?email=' + email);
  }
  getUserAddress() {
    return this.http.get<IAddress>(this.baseUrl + 'account/address');
  }

  updateUserAddress(address: IAddress) {
    return this.http.put(this.baseUrl + 'account/address', address);
  }
  getBillingDetail() {
    return this.http.get<IBillingDetail>(this.baseUrl + 'account/billingDetails');
  }
 // updateBillingDetail(billingDetail: IBillingDetail) {
    //return this.http.put<IBillingDetail>(this.baseUrl + 'account/billingDetails', billingDetail);
 // }
 // addAddress(address: IAddress) {
    //return this.http.post(this.baseUrl + 'shippingAddress/address', address);
  //}
  addBillingDetail(billingDetail: IBillingDetail) {
    return this.http.post<IBillingDetail>(this.baseUrl + 'shippingAddress/billingDetails', billingDetail);
  }
  public forgotPassword = (route: string, body: ForgotPassword) => {
    return this.http.post(this.createCompleteRoute(route, this.baseUrl), body);
  }
  public resetPassword = (route: string, body: ResetPasswordDto) => {
    return this.http.post(this.createCompleteRoute(route, this.baseUrl), body);
  }
  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}${route}`;
  }
  

}
