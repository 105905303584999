import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { IBrand } from 'src/app/shared/models/brand';

@Component({
  selector: 'app-update-brand',
  templateUrl: './update-brand.component.html',
  styleUrls: ['./update-brand.component.css']
})
export class UpdateBrandComponent implements OnInit {
  branddetail: IBrand = {
    id: 0,
    name: ''
  };
  constructor(private route: ActivatedRoute, private service: AdminService, private router: Router ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (params) => {
        const id = params.get('id');

        if (id) {
          this.service.getBrandswithId(id)
          .subscribe({
            next: (response) => {
              this.branddetail = response;
            }
          });
        }
      }
    })
  }
  updateBrand() {
   this.service.updateBrand(this.branddetail.id, this.branddetail)
   .subscribe({
    next: (response) => {
      this.router.navigate(['adminProductBrand'])
    }
   });
  }

}
