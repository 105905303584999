<div class="page-header">
    <div class="container">
        <h1>{{header}}</h1>
    </div>
  </div>
  
  <div class="container">
    <div class="row top-row" style="padding-left: 5px;" >
        <div class="col-lg-2 row end-xs">
            <a routerLink="/roastingoption" color="primary" mat-raised-button>
                <mat-icon>chevron_left</mat-icon>
                Go Back
            </a>
        </div>
    </div>
  <br>
  <ng-container>
    <div class="row">
        <div class="col-lg-6">
            <form #productDetailsForm="ngForm">
              <div class="row">
                <div class="col-xs-12">
                  <mat-form-field appearance="outline"  style="width: 400px;padding-left: 5px;">
                    <mat-label>Id: </mat-label>
                    <input type="text" matInput [(ngModel)]="variance.id" readonly  name="id">
                   </mat-form-field>
                </div>
             </div>
             <div class="row">
                <div class="col-xs-12">
                  <mat-form-field appearance="outline"  style="width: 400px;padding-left: 5px;">
                    <mat-label>Roasting Option Name: </mat-label>
                    <input type="text" matInput [(ngModel)]="variance.weightCoffee" name="roastingName">
                   </mat-form-field>
                </div>
             </div>
             <div class="row">
              <div class="col-xs-12">
                <mat-form-field appearance="outline"  style="width: 400px;padding-left: 5px;">
                  <mat-label>Stock: </mat-label>
                  <input type="text" matInput [(ngModel)]="variance.priceCoffee" name="stock">
                 </mat-form-field>
              </div>
           </div>
              <div class="row">
                <div class="col-xs-6">
                  <div class="row"  *ngIf="!isNewProduct" style="width: 300px;padding-left: 5px;">
                    <div class="col-3"> <button mat-raised-button color="primary" (click)="onUpdate()">Save</button></div>
                    <div class="col-3"><button mat-raised-button color="warn" (click)="onDelete()">Delete</button></div>
                  </div>
                  <div class="row"  *ngIf="isNewProduct">
                    <div class="col-3"> <button mat-raised-button color="primary" (click)="onAdd()">Add</button></div>
                  </div>
                </div>
              </div>
            </form>
         </div>
        
    </div>
   </ng-container>
  
  </div>
 
  <br>
  <br>
  <br>
  
