<div class="form-floating mb-3">
    <input 
      type={{type}} 
      [formControl]="control"
      class="form-control"
      [ngClass]="(control.touched) ? control.invalid ? 'is-invalid' : 'is-valid' : null">
    <div *ngIf="control.status === 'PENDING'" class="fa fa-spinner fa-spin loader"></div>
    <label for="floatingInput">{{label}}</label>
    <div *ngIf="control.errors?.['required']" class="invalid-feedback">Please enter your {{label}}</div>
    <div *ngIf="control.errors?.['email']" class="invalid-feedback">Invalid email address</div>
    <div *ngIf="control.errors?.['pattern']" class="invalid-feedback">Password must be at least 8 Characters long.</div>
    <div *ngIf="control.errors?.['emailExists']" class="invalid-feedback">Email address is taken</div>
  </div>
<!--<div class="form-label-group">
    <input 
        [ngClass]="(controlDir?.control?.touched) 
            ? !controlDir.control.valid ? 'is-invalid' : 'is-valid' : null"
        [type]="type"
        (input)="onChange($event.target.value)"
        (blur)="onTouched()"
        #input
        class="form-control"
        placeholder="{{label}}"
    >
    <div *ngIf="controlDir?.control?.status === 'PENDING'" class="fa fa-spinner fa-spin loader"></div>
    <label for="{{label}}">{{label}}</label>
    <div class="invalid-feedback" *ngIf="(!controlDir?.control?.valid && controlDir.control?.touched)">
        <span *ngIf="controlDir.control.errors?.required">{{label}} is required</span>
        <span *ngIf="controlDir.control.errors?.pattern">Invalid email address</span>
        
    </div>

    <div class="invalid-feedback d-block" *ngIf="(!controlDir?.control?.valid && controlDir?.control?.dirty)" >
        <span *ngIf="controlDir.control.errors?.emailExists">Email address is in use</span>
    </div>
</div>-->

