<!--Start rev slider wrapper-->  
<br>
<br>   
<section class="rev_slider_wrapper">
    <div id="slider1" class="rev_slider"  data-version="5.0" >
        
        <ul>
            <li data-transition="fade">
                <img src="assets/images/slides/cup.png"  alt="" width="1920" height="500" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1">
                
                <div class="tp-caption  tp-resizeme" 
                    data-x="left" data-hoffset="0" 
                    data-y="top" data-voffset="370" 
                    data-transform_idle="o:1;"         
                    data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" 
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" 
                    data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" 
                    data-splitin="none" 
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-start="700">
                    
                    <div class="slide-content left-slide">
                        <div class="title">TASTE THE MELODY OF KIRAR</div>
                        <div class="big-title">KIRAR COFFEE</div>
                        <div class="text">The best organic coffee beans.</div>
                        <div class="btns-box">
                           <!-- <a href="about.html" class="thm-btn pdone">Read More</a>&ensp; -->
                            <a href="shop" class="thm-btn btn-style-two pdtwo">Shop</a> 
                        </div>
                    </div>
                </div>
            </li>
            <li data-transition="fade">
                <img src="assets/images/resources/coffee_b.jpg" alt="" width="1920" height="500" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" >
                
               <div class="tp-caption  tp-resizeme" 
                    data-x="right" data-hoffset="0" 
                    data-y="top" data-voffset="370" 
                    data-transform_idle="o:1;"         
                    data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" 
                    data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" 
                    data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" 
                    data-splitin="none" 
                    data-splitout="none"
                    data-responsive_offset="on"
                    data-start="1500">
                    
                    <div class="slide-content right-slide">
                        <div class="title">TASTE THE MELODY OF KIRAR</div>
                        <div class="big-title">KIRAR COFFEE</div>
                        <div class="text">The best organic coffee beans.</div>
                        <div class="btns-box">
                            <a href="shop" class="thm-btn btn-style-two pdtwo">Shop</a> 
                        </div>
                    </div>
                </div>
            </li>
         
        </ul>
    </div>
</section>
<!--End rev slider wrapper--> 



<!--Start services area-->
<!-- <section class="services-area">
    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="sec-title text-center">
                     
                        <div class="border center color-2"></div> 
                        <h1>Welcome Kirar Store</h1>
                    </div>
                </div>
            </div>
        </div>
     
    </div>
</section>-->
<!--==============================================-->
<div class="video-galley-content parallax-bg-one" style="background-image:url(assets/images/resources/spices3.jpg);height: 80px;">
   <div class="container">
       <div class="row">
           <div class="col-md-12">
               <div class="inner-content text-center">
                   <div class="title"><h1>Welcome Kirar Store</h1></div>
                  <!---<div class="text"><p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot.</p></div> --> 
                   <div class="icon-holder">
                      <!-- <div class="icon">
                           <a class="html5lightbox" title="inFocus Video Gallery" href="https://www.youtube.com/watch?v=nMPqsjuXDmE">
                               <span class="flaticon-play"></span>
                           </a>   
                       </div>-->
                   </div>
                 <!--  <a class="video-partner" href="#">Video about our partners</a>-->
               </div>
           </div>
       </div>
   </div>   
</div>
<!--===================================================-->

<!--End fact counter area-->

<!--Start project area-->
<section class="latest-project-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="sec-title">
                    <span>Our Cases</span>
                    <div class="border"></div> 
                    <h1>Krar Coffee</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div class="left-content">
                    <h3></h3>
                    <p>Since 2019, Kirar has been crafting the finest coffee by carefully selecting and hand roasting the best organic coffee beans.  At Kirar, exceptional ingredients are only the first step. What sets us apart from the pack is our heartfelt dedication to producing coffee that not only tastes amazing but is responsibly sourced. We guarantee you a coffee experience like no other.</p>
                   <!-- <a href="about">Read More<i class="fa fa-angle-right" aria-hidden="true"></i></a>-->
                </div>
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <div class="right-content">
                    <div class="projects-carousel owl-nav-style-one">
                      
                        <div class="single-project-item">
                            <div class="img-holder">
                                <img src="assets/images/projects/ginger.png" alt="Awesome Image">
                                <div class="static-overlay" style="text-align: center">
                                    <h3 ><a href="#">Ginger</a></h3>
                                    
                                </div>
                                <div class="overlay"  style="background: black">
                                    <div class="content">
                                        <h3 style="color: orange">Ginger</h3>
                                       
                                        <h6 style="color: #fff">Ginger has a long and rich history in traditional and alternative medicine, It’s has been used to aid digestion, reduce nausea, boost immunity, improve stomach performance, reduce inflammation, fight respiratory problems, relieve stress, and help fight the flu and common cold. It can be blended into smoothies or boiled into a tea, It’s essential in everyone’s kitchen. Ginger Origin Limmu Wolega, Ethiopia.</h6>
                                        <a class="readmore" href="/about">Read More<i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                    <a class="btn warning" href="/shop" >Shop</a> 
                                </div>
                                
                            </div>  
                            
                        </div> 
                      
                        <div class="single-project-item">
                            <div class="img-holder">
                                <img src="assets/images/projects/coffee.png" alt="Awesome Image">
                                <div class="static-overlay">
                                    <h3 style="text-align: center"><a href="#">Coffee</a></h3>
                                    
                                </div>
                                <div class="overlay" style="background: black">
                                    <div class="content">
                                        <h3 style="color: orange">Coffee</h3>
                                        
                                        <h6 style="color: #fff">At Kirar exceptional ingredients is only the first step. What sets us apart from the pack is our heartfelt dedication to producing coffee that not only tastes amazing but is responsibly sourced artisanally roasted, and creatively packaged. We guarantee you a coffee experience like no other bold and modern..</h6>
                                        <a class="readmore" href="/about">Read More<i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                    <a class="btn warning" href="/shop" >Shop</a> 
                                </div>
                            </div>   
                        </div> 
                       
                        <div class="single-project-item">
                            <div class="img-holder">
                                <img src="assets/images/projects/berbere.png" alt="Awesome Image">
                                <div class="static-overlay">
                                    <h3 style="text-align: center"><a href="#">Berbere</a></h3>
                                   
                                </div>
                                <div class="overlay" style="background: black">
                                    <div class="content">
                                        <h3 style="color: orange">Berbere</h3>
                                      
                                        <p>Garlic - ነጭ ሽንኩርት, Ginger – ዝንጅብል, Fresh Basil – በሶብላ, Rue – ጤናዳም,Black Cardamom - ኮረሪማ , Fenugreek - አብሽ , Cumin - ነጭ አዝሙድ , Black Cumin Seed - ጥቁር አዝሙድ, Nutmeg – ዘውገ, Cumin – ከሙን, Dired Basil - ደረቅ በሶብላ Store in an airtight container in the pantry, refrigerate after opening</p>
                                        <a class="readmore" href="/about">Read More<i class="fa fa-angle-right" aria-hidden="true"></i></a>
                                    </div>
                                    
                                    <a class="btn warning" href="/shop" >Shop</a> 
                                </div>
                            </div>   
                        </div> 
                       
                    </div>           
                </div> 
            </div>
        </div>
    </div>
</section> 
<!--End project area--> 

<!--Start video gallery area-->
 <section class="video-gallery-area">
   <!-- <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="featured-content-box flex-box text-center">
                   
                 <div class="single-featured-box bg-one">
                        <div class="icon-holder">
                            <span class="flaticon-clock"></span>
                        </div>
                        <div class="text-holder">
                            <h3>On Time Service</h3>
                            <p>We are experts in industry with over 100 years of experience. What that means you are get right solution, experts.</p>
                        </div>
                    </div>
                   
                    <div class="single-featured-box bg-two">
                        <div class="icon-holder">
                            <span class="flaticon-people-1"></span>
                        </div>
                        <div class="text-holder">
                            <h3>Experineced Team</h3>
                            <p>Advanced Analytics is an unique add-on service  offering the SFinancewhich enables you to work trends organization.</p>
                        </div>
                    </div>
                 
                    <div class="single-featured-box bg-three">
                        <div class="icon-holder">
                            <span class="flaticon-chart-1"></span>
                        </div>
                        <div class="text-holder">
                            <h3>Analyze Your Business</h3>
                            <p>Is an interprtation of trends in human behaviors which aims to increase effectiveness of a sed service for the consumer.</p>
                        </div>
                    </div>
                 
                </div>    
            </div>
        </div>
    </div> -->
    <div class="video-galley-content parallax-bg-one" style="background-image:url(assets/images/parallax-background/coffee_c.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="inner-content text-center">
                        <div class="title"><h1>We are trusted by our customers</h1></div>
                        <div class="text"><p>Coffee does more than boost your energy. A few daily cups of coffee may also lower your risk of type 2 diabets and depression, support weight management,  and help you live a longer life.</p></div> 
                        <div class="icon-holder">
                           <a class="btn warning" href="/shop">Check our Products</a>
                           <!-- <div class="icon">
                                <a class="html5lightbox" title="inFocus Video Gallery" href="https://www.youtube.com/watch?v=nMPqsjuXDmE">
                                    <span class="flaticon-play"></span>
                                </a>   
                            </div>-->
                        </div>
                      <!--  <a class="video-partner" href="#">Video about our partners</a>-->
                    </div>
                </div>
            </div>
        </div>   
    </div>
</section>

  <!--  </div>
</section>-->
<!--End latest blog area--> 

<!--Start Brand area-->  
<!---<section class="brand-area">
    <div class="container">
        <div class="sec-title text-center">
            <span>Who Trust Us</span>
            <div class="border center"></div> 
            <h1>Our Trusted Partners</h1>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="brand owl-dot-style-one">
                   
                    <div class="single-item">
                        <a href="#"><img src="assets/images/brand/1.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>There anyone who loves or pursues or desires sed to obtain pain of itself, because it is pain, but because occasionally sed great.</p>
                        </div>
                    </div>
                   
                    <div class="single-item">
                        <a href="#"><img src="assets/images/brand/2.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>Except to obtain some advantage from it but who has any right to find fault with a man who to enjoy a pleasure that annoying.</p>
                        </div>
                    </div>
                   
                    <div class="single-item">
                        <a href="#"><img src="assets/images/brand/3.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>Who loves or pursues or desires to obtain pain itself, because is pain, but because occasionally utcircumstances occur which toil.</p>
                        </div>
                    </div>
                  
                    <div class="single-item">
                        <a href="#"><img src="assets/images/brand/1.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>There anyone who loves or pursues or desires sed to obtain pain of itself, because it is pain, but because occasionally sed great.</p>
                        </div>
                    </div>
                    
                    <div class="single-item">
                        <a href="#"><img src="assets/images/brand/2.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>Except to obtain some advantage from it but who has any right to find fault with a man who to enjoy a pleasure that annoying.</p>
                        </div>
                    </div>
                   
                    <div class="single-item">
                        <a href="#"><img src="images/brand/3.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>Who loves or pursues or desires to obtain pain itself, because is pain, but because occasionally utcircumstances occur which toil.</p>
                        </div>
                    </div>
                   
                    <div class="single-item">
                        <a href="#"><img src="assets/images/brand/1.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>There anyone who loves or pursues or desires sed to obtain pain of itself, because it is pain, but because occasionally sed great.</p>
                        </div>
                    </div>
                   
                    <div class="single-item">
                        <a href="#"><img src="assets/images/brand/2.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>Except to obtain some advantage from it but who has any right to find fault with a man who to enjoy a pleasure that annoying.</p>
                        </div>
                    </div>
                   
                    <div class="single-item">
                        <a href="#"><img src="assets/images/brand/3.png" alt="Awesome Brand Image"></a>
                        <div class="text-holder">
                            <p>Who loves or pursues or desires to obtain pain itself, because is pain, but because occasionally utcircumstances occur which toil.</p>
                        </div>
                    </div>
                   
                   
                </div>
            </div>
        </div>
    </div>
</section>--> 
<!--End Brand area-->  
<!--<div id="home-slider" class="owl-carousel owl-theme">
<div class="item">
   <img src="../assets/wwwroot/img/cup.png" alt="slide-1" class="img-responsive">
   <div class="slider-desc">
       <div class="container">
           <div class="row">
               <div class="col-md-6">
                   <div class="slide-offers-left">
                       <div class="slide-offers-title" style="color: #83502e"><span>𝕶𝖎𝖗𝖆𝖗</span><br/>COFFEE</div>
                       <p style="color: #83502e"><i></i></p><br/>
                     <a href="/shop" class="btn btn-blue btn btn-warning" style="background: #FF6F61">Shop now</a>
                   </div>
               </div>
               <div class="col-md-6">
                    <div class="slide-offers-right">
                       <div class="slide-offers-title" style="color: #83502e">
                         
                           ░▒▓█ 𝒯𝒜𝒮𝒯ℰ 𝒯ℋℰ ℳℰℒ𝒪𝒟𝒴 𝒪ℱ 𝒦ℐℛ𝒜ℛ █▓▒░
                       </div>
                     
                       <a></a>
                   </div>
               </div>
           </div>
       </div>
   </div>
</div>-->


<!--<section class="featured">

<div class="feature">
<div class="container-fluid">
   <div class="row align-items-center">
       <div class="col-lg-3 col-md-6 feature-col">
           <div class="feature-content">
               <i class="fab fa-cc-mastercard"></i>
               <h2>Secure Payment</h2>
               <p>
                  
               </p>
           </div>
       </div>
       <div class="col-lg-3 col-md-6 feature-col">
           <div class="feature-content">
               <i class="fa fa-truck"></i>
               <h2>Worldwide Delivery</h2>
               <p>
                   
               </p>
           </div>
       </div>
       <div class="col-lg-3 col-md-6 feature-col">
           <div class="feature-content">
               <i class="fa fa-undo"></i>
               <h2>30 Days Return</h2>
               <p>
                  
               </p>
           </div>
       </div>
       <div class="col-lg-3 col-md-6 feature-col">
           <div class="feature-content">
               <i class="fa fa-comments"></i>
               <h2>24/7 Support</h2>
               <p>
                   
               </p>
           </div>
       </div>
   </div>
</div>
</div>
</section>-->


<!--================================Old code=======================================================-->

<!--<div id="home-slider" class="owl-carousel owl-theme">
    <div class="item">
        <img src="../assets/wwwroot/img/cup.png" alt="slide-1" class="img-responsive">
        <div class="slider-desc">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="slide-offers-left">
                            <div class="slide-offers-title" style="color: #83502e"><span>𝕶𝖎𝖗𝖆𝖗</span><br/>COFFEE</div>
                            <p style="color: #83502e"><i></i></p><br/>
                          <a href="/shop" class="btn btn-blue btn btn-warning" style="background: #FF6F61">Shop now</a>
                        </div>
                    </div>
                    <div class="col-md-6">
                         <div class="slide-offers-right">
                            <div class="slide-offers-title" style="color: #83502e">
                              
                                ░▒▓█ 𝒯𝒜𝒮𝒯ℰ 𝒯ℋℰ ℳℰℒ𝒪𝒟𝒴 𝒪ℱ 𝒦ℐℛ𝒜ℛ █▓▒░
                            </div>
                          
                            <a></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  
<section class="featured">

  <div class="feature">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 feature-col">
                <div class="feature-content">
                    <i class="fab fa-cc-mastercard"></i>
                    <h2>Secure Payment</h2>
                    <p>
                       
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 feature-col">
                <div class="feature-content">
                    <i class="fa fa-truck"></i>
                    <h2>Worldwide Delivery</h2>
                    <p>
                        
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 feature-col">
                <div class="feature-content">
                    <i class="fa fa-undo"></i>
                    <h2>30 Days Return</h2>
                    <p>
                       
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 feature-col">
                <div class="feature-content">
                    <i class="fa fa-comments"></i>
                    <h2>24/7 Support</h2>
                    <p>
                        
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</section>-->
    