<div class="container mt-2" >
  <div class="col-lg-3">
  </div>
    <div class="col-9">
      <div class="row end-xs mt-1">
      
        <div class="col-xs-12">
         <input type="text" class="search-input" placeholder="Search products" 
         [(ngModel)] ="filterString"
         (keyup)="filterProducts()">
         &nbsp;&nbsp; <a (click)="logout()" class="fa fa-sign-out mr-4" style="color: black; size: 5">
           Logout
         </a> 
        </div>
       </div>
    <br>
  
    <div class="row" >
      <div class="col-lg-3">
        <ul style="height: 500px">
          <li><a class="active" href="/adminProduct01102023/add">Add New product</a></li>
          <li><a href="userOrder">Order</a></li>
          <li><a href="adminSignin&drhersfhgytrgd">Register</a></li>
          <li><a href="adminProductBrand">Product Brand</a></li>
          <li><a href="admindelivery">Grind</a></li>
          <li><a href="roastingoption">Weight Option</a></li>
        </ul>
      </div>
   
      <div class="col-lg-9" style="padding-left: 10px">
        <div class="row">
          <div class="mat-elevation-z8"> 
           <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="theader">Id</th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
             <ng-container matColumnDef="name">
               <th mat-header-cell *matHeaderCellDef class="theader">Name</th>
               <td mat-cell *matCellDef="let element"> {{element.name}} </td>
             </ng-container>
             <ng-container matColumnDef="scale" >
               <th mat-header-cell *matHeaderCellDef class="theader">Scale</th>
               <td mat-cell *matCellDef="let element" > {{element.scale}} </td>
             </ng-container>
             <ng-container matColumnDef="price">
               <th mat-header-cell *matHeaderCellDef class="theader"> Price </th>
               <td mat-cell *matCellDef="let element" > {{element.price}} </td>
             </ng-container>
             
              <ng-container matColumnDef="edit">
               <th mat-header-cell *matHeaderCellDef > </th>
               <td mat-cell *matCellDef="let element">
                 <a href="/adminProduct01102023/{{element.id}}" > <mat-icon color="primary">edit</mat-icon></a> 
               </td>
             </ng-container>
             <ng-container matColumnDef="delete">
               <th mat-header-cell *matHeaderCellDef ></th>
               <td mat-cell *matCellDef="let element;  let j ;">
                 <a href="/adminProduct01102023/{{element.id}}" > <mat-icon color="warn">delete</mat-icon></a> 
               </td>
             </ng-container>
             
             <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
             <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           
           </table>
           <mat-paginator style="color: orange"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
           aria-label="Select page">
         </mat-paginator>
         </div>
         </div>
      </div>
    </div>
   
  </div>
  </div>
  
  <br>
  <br>
