import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about.component';


const routes: Routes = [
  {path: 'about', component: AboutComponent}
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule,
  ],
  exports: [RouterModule]
})
export class AboutRoutingModule { }
