<div class="page-header">
  <div class="container">
      <h1>{{header}}</h1>
  </div>
</div>

<div class="container">
  <div class="row top-row" style="padding-left: 5px;" >
      <div class="col-lg-2 row end-xs">
          <a routerLink="/adminProduct01102023" color="primary" mat-raised-button>
              <mat-icon>chevron_left</mat-icon>
              Go Back
          </a>
      </div>
  </div>
<br>
<ng-container>
  <div class="row">
      <div class="col-lg-6">
          <form #productDetailsForm="ngForm">
           <div class="row">
              <div class="col-xs-12">
                <mat-form-field appearance="outline"  style="width: 400px;padding-left: 5px;">
                  <mat-label>Name: </mat-label>
                  <input type="text" matInput [(ngModel)]="product.name" name="name">
                 </mat-form-field>
              </div>
           </div>
           <div class="row" *ngIf="product.name == 'Coffee' || product.productBrand == 'Roast Coffee'">
                <div class="col-xs-12">
                  <mat-form-field class="example-full-width" appearance="outline"  style="width: 400px;padding-left: 5px;">
                    <mat-label>Roast Options: </mat-label>
                    <input type="text" matInput [(ngModel)]="product.roastingOption" name="coffeeOption">
                   </mat-form-field>
                </div>
               <div class="col-xs-12" >
                  <mat-form-field class="example-full-width" appearance="outline"  style="width: 400px;padding-left: 5px;">
                    <mat-label>Grind Name: </mat-label>
                    <input type="text" matInput [(ngModel)]="product.grindName" name="grindName">
                   </mat-form-field>
                </div>
                <div class="col-xs-12" >
                  <mat-form-field class="example-full-width" appearance="outline"  style="width: 400px;padding-left: 5px;">
                    <mat-label>Coffee Price Min-Max: </mat-label>
                    <input type="text" matInput [(ngModel)]="product.sku" name="sku">
                   </mat-form-field>
                </div>
              </div>
              <div class="row">
              <div class="col-xs-6">
                  <mat-form-field appearance="outline" style="width: 400px;padding-left: 5px;">
                    <mat-label>Price: </mat-label>
                    <input type="text" matInput [(ngModel)]="product.price" name="price">
                   </mat-form-field>
                </div>
           </div>
           <div class="row">
              <div class="col-xs-6">
                <mat-form-field appearance="outline"  style="width: 400px;padding-left: 5px;">
                  <mat-label>productBrand: </mat-label>
                  <input type="text" matInput [(ngModel)]="product.productBrand" name="productBrand">
                 </mat-form-field>
              </div>
           </div>
           <div class="row">
              <div class="col-xs-6">
                  <mat-form-field appearance="outline"  style="width: 400px; padding-left: 5px;">
                    <mat-label>productType: </mat-label>
                    <input type="text" matInput [(ngModel)]="product.productType" name="productType">
                   </mat-form-field>
                </div>
           </div>
           <div class="row">
            <div class="col-xs-12">
              <mat-form-field class="example-full-width" appearance="outline" style="width: 400px;padding-left: 5px;">
                <mat-label>Scale: </mat-label>
                <input type="text" matInput [(ngModel)]="product.scale" name="scale">
               </mat-form-field>
            </div>
          </div>
            <div class="row">
              <div class="col-xs-12">
                  <mat-form-field class="example-full-width" appearance="outline" style="width: 400px;padding-left: 5px;">
                    <mat-label>Description: </mat-label>
                    <textarea type="text" matInput [(ngModel)]="product.description" name="description"></textarea>
                   </mat-form-field>
                </div>
            </div>
            <div class="col-xs-12" >
              <mat-form-field class="example-full-width" appearance="outline"  style="width: 400px;padding-left: 5px;">
                <mat-label>Stock: </mat-label>
                <input type="text" matInput [(ngModel)]="product.stock" name="roastingOption">
               </mat-form-field>
            </div> 
            <div class="row">
              <div class="col-xs-6">
                <div class="row"  *ngIf="!isNewProduct" style="width: 300px;padding-left: 5px;">
                  <div class="col-3"> <button mat-raised-button color="primary" (click)="onUpdate()">Save</button></div>
                  <div class="col-3"><button mat-raised-button color="warn" (click)="onDelete()">Delete</button></div>
                </div>
                <div class="row"  *ngIf="isNewProduct">
                  <div class="col-3"> <button mat-raised-button color="primary" (click)="onAdd()">Add</button></div>
                </div>
              </div>
            </div>
          </form>
       </div>
       <div class="col-lg-4" style="padding-left: 5px;">
         <div class="row center-xs">
          <div class="image-container" style="padding-left: 5px;">
            <img [src]="displayProfileImageUrl" alt="Profile Image">
          </div>
         </div>
         <div class="row row center-xs mt-1" style="padding-left: 5px;">
          <div class="col-xs-3 row center-xs mt-1" *ngIf="!isNewProduct">
            <input hidden type="file" #imageUpload (change)="uploadImage($event)">
            <button mat-raised-button color="primary" (click)="imageUpload.click()">Change Image</button>
          </div>
         </div>
      </div>
  </div>
 </ng-container>

</div>
<br>
<br>
<br>
