<div class="container">
      <div class="d-flex justify-content-center mt-5">
        <div class="row top-row">
          <div class="col-lg-2 row end-xs" >
              <a routerLink="/adminProduct01102023" color="primary" mat-raised-button>
                  <mat-icon>chevron_left</mat-icon>
                  Go Back
              </a>
          </div>
      </div>
      <br>
    <div class="row ml-1">
        <div class="mat-elevation-z8"> 
          <a class="active" href="/viewRoastingoption/add"><h3 style="color:orange">Add New product</h3></a>
         <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="theader">Id</th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
           <ng-container matColumnDef="weightCoffee">
             <th mat-header-cell *matHeaderCellDef class="theader th">Weight</th>
             <td mat-cell *matCellDef="let element" class="th"> {{element.weightCoffee}} </td>
           </ng-container>
           <ng-container matColumnDef="priceCoffee">
            <th mat-header-cell *matHeaderCellDef class="theader th">Price</th>
            <td mat-cell *matCellDef="let element" class="th"> {{element.priceCoffee}} </td>
          </ng-container>
            <ng-container matColumnDef="edit">
             <th mat-header-cell *matHeaderCellDef > </th>
             <td mat-cell *matCellDef="let element" class="th">
               <a href="/viewRoastingoption/{{element.id}}" > <mat-icon color="primary">edit</mat-icon></a> 
             </td>
           </ng-container>
           <ng-container matColumnDef="delete">
             <th mat-header-cell *matHeaderCellDef ></th>
             <td mat-cell *matCellDef="let element;  let j ;">
               <a href="/viewRoastingoption/{{element.id}}" > <mat-icon color="warn">delete</mat-icon></a> 
             </td>
           </ng-container>
           
           <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
           <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
           
         </table>
       </div>
       </div>
    </div>
    <br>
