import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from './core/interceptors/loading.interceptors';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { OrdersComponent } from './orders/orders.component';
import { AboutModule } from './about/about.module';
import { ContactModule } from './contact/contact.module';
import { ContactComponent } from './contact/contact.component';
import { AdminComponent } from './admin/admin/admin.component';
//import { UploadComponent } from './admin/upload/upload.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule, } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import {MatTableDataSource} from '@angular/material/table';
import { AdminModule } from './admin/admin/admin.module';
//import { ViewProductComponent } from './admin/view-product/view-product.component';
//import { AdminsComponent } from './admins/admins.component';
//import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
//import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
//import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
//import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
//import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
//import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
//import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
//import {MatSortModule} from '@angular/material/sort';
//import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
//import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UploadComponent } from './admin/upload/upload.component';
import { ViewProductComponent } from './admin/view-product/view-product.component';
import { UserOrderComponent } from './admin/user-order/user-order.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminSigninComponent } from './admin/admin-signin/admin-signin.component';
import { ViewOrderComponent } from './admin/view-order/view-order.component';
import { OrderItemsComponent } from './admin/order-items/order-items.component';
import { AdminProductBrandComponent } from './admin/admin-product-brand/admin-product-brand.component';
import { UpdateBrandComponent } from './admin/update-brand/update-brand.component';
import { AdminDeliveryComponent } from './admin/admin-delivery/admin-delivery.component';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
//import { UpdateDeliverComponent } from './admin/update-deliver.component';
import { UpdateDeliveryComponent } from './admin/update-delivery/update-delivery.component';
import { RoastoptionComponent } from './admin/roastoption/roastoption.component';
import { ViewRoastoptionComponent } from './admin/view-roastoption/view-roastoption.component';
//import { UserOrderComponent } from './admin/user-order/user-order.component';

@NgModule({
  declarations: [
    AppComponent,
    ViewProductComponent,
    UserOrderComponent,
    AdminProductComponent,
    ViewOrderComponent,
    OrderItemsComponent,
    AdminProductBrandComponent,
    UpdateBrandComponent,
    AdminDeliveryComponent,
   // UpdateDeliverComponent,
    UpdateDeliveryComponent,
   RoastoptionComponent,
   ViewRoastoptionComponent
   
  ],
  imports: [
    CommonModule, 
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    HomeModule,
    AboutModule,
   // AdminModule,
    FormsModule,
    NgxSpinnerModule,
    AppRoutingModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTreeModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
