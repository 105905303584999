import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrderItem } from 'src/app/shared/models/order';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.css']
})
export class OrderItemsComponent implements OnInit {
  orders: IOrderItem[];
  filterString = '';
  displayedColumns: string[] = ['id','itemOrdered.productName','price','quantity','itemOrdered.coffeeOption','itemOrdered.scale','orderId','grindName','edit','delete'];
  dataSource: MatTableDataSource<IOrderItem> = new MatTableDataSource<IOrderItem>();

 @ViewChild(MatPaginator) matPaginator: MatPaginator;
 @ViewChild(MatSort) sort: MatSort;
  constructor(private http: HttpClient, public service : AdminService, 
    private route: ActivatedRoute, private router: Router) { } 

  ngOnInit(): void {
    this.GetOrderItem();
  }
  GetOrderItem() {
    this.service.getOrderItems()
    .subscribe(
      (successResponse: any) => {
        debugger;
        this.orders = successResponse;
         this.dataSource = new MatTableDataSource<IOrderItem>(this.orders);
        if (this.matPaginator) {
          this.dataSource.paginator = this.matPaginator;
        }
      },
      (errorResponse) => {
        console.log(errorResponse)
      });
    }
    filterProducts() {
    this.dataSource.filter = this.filterString.trim().toLowerCase();
   }
   ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
   
}
