import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminSigninComponent } from './admin/admin-signin/admin-signin.component';
import { AdminComponent } from './admin/admin/admin.component';
import { UserOrderComponent } from './admin/user-order/user-order.component';
import { ViewOrderComponent } from './admin/view-order/view-order.component';
import { ViewProductComponent } from './admin/view-product/view-product.component';
import { ContactComponent } from './contact/contact.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './core/not-found/not-found.component';
import { ServerErrorComponent } from './core/server-error/server-error.component';
import { TestErrorComponent } from './core/test-error/test-error.component';
import { HomeComponent } from './home/home.component';
import { AdminProductBrandComponent } from './admin/admin-product-brand/admin-product-brand.component';
import { UpdateBrandComponent } from './admin/update-brand/update-brand.component';
import { AdminDeliveryComponent } from './admin/admin-delivery/admin-delivery.component';
import { UpdateDeliveryComponent } from './admin/update-delivery/update-delivery.component';
import { RoastoptionComponent } from './admin/roastoption/roastoption.component';
import { ViewRoastoptionComponent } from './admin/view-roastoption/view-roastoption.component';


const routes: Routes = [
  {path: '', component: HomeComponent,  data: {breadcrumb: 'Home'}},
  { path: 'test-error', component: TestErrorComponent, data: { breadcrumb: 'Test Errors' } },
  { path: 'server-error', component: ServerErrorComponent, data: { breadcrumb: 'Server Error' } },
  { path: 'not-found', component: NotFoundComponent, data: { breadcrumb: 'Not found' } },
  { path: 'about', component: AboutComponent, data: {breadcrumb: 'About'}},
  { path: 'contact', component: ContactComponent, data: {breadcrumb: 'Contact us'}},
  { path: 'admin', component: AdminComponent, data: {breadcrumb: 'Admin'}},
  { path: 'admin/:id', component: ViewProductComponent},
  { path: 'userOrder', component: UserOrderComponent, data: {breadcrumb: 'userOrder' }},
  { path: 'adminProduct01102023', component: AdminProductComponent, data: {breadcrumb: 'adminProduct01102023'}},
  { path: 'adminProduct01102023/:id', component: ViewProductComponent},
  { path: 'adminSignin&drhersfhgytrgd', component: AdminSigninComponent},
  { path: 'adminProductBrand', component: AdminProductBrandComponent},
  { path: 'admindelivery', component: AdminDeliveryComponent},
  { path: 'viewOrder/:id', component: ViewOrderComponent},
  { path: 'viewOrder/:id', component: ViewOrderComponent},
  { path: 'updateproductbrand/:id', component: UpdateBrandComponent},
  { path: 'updatedelivery/:id', component: UpdateDeliveryComponent},
  { path: 'roastingoption', component: RoastoptionComponent },
  { path: 'viewRoastingoption/:id', component: ViewRoastoptionComponent },
  {
    path: 'shop', loadChildren: () => import('./shop/shop.module').then(mod => mod.ShopModule),
   data: {breadcrumb: 'Shop'}
  },
  
   {
    path: 'basket', loadChildren: () => import('./basket/basket.module').then(mod => mod.BasketModule),
    data: {breadcrumb: 'Basket'}
  },
  {
    path: 'checkout', 
    canActivate: [AuthGuard],
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
  },
  {
    path: 'orders', 
    canActivate: [AuthGuard],
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
  },
 /* {
    path: 'checkout', 
    canActivate: [AuthGuard],
    loadChildren: () => import('./checkout/checkout.module').
    then(mod => mod.CheckoutModule), data: { breadcrumb: 'Checkout' }
  },
  {
    path: 'orders', 
    canActivate: [AuthGuard],
    loadChildren: () => import('./orders/orders.module').then(mod => mod.OrdersModule),
    data: { breadcrumb: 'Orders' }
  },*/
   {
    path: 'account', 
    loadChildren: () => import('./account/account.module').then(mod => mod.AccountModule),
   // data: { breadcrumb: {skip: true} }
  },
  
   {path: '**', redirectTo: 'not-found', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
