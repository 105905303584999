import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { IAminUser } from 'src/app/shared/models/adminUser';
import { Product } from 'src/app/shared/models/product';
import { IProductAdmin } from 'src/app/shared/models/productadmin';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.css']
})
export class ViewProductComponent implements OnInit {
 // currentUser$: Observable<IAminUser>;
  productId: string | null | undefined;
  product: IProductAdmin = {
    id: 0,
    name: '',
    description: '',
    pictureUrl: '',
    price: 0,
    scale: '',
    productBrand: '',
    productType: '',
    roastingOption: '',
    sku: '',
    stock: 0,
    grindName: ''
  }
  isNewProduct = false;
   header = '';
   displayProfileImageUrl = '';
   currentuser: string;
  constructor(private service : AdminService, private readonly route: ActivatedRoute, 
    private snackbar: MatSnackBar, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (params) => {
        this.productId = params.get('id');
       // this.currentUser$ = this.service.currentUser$;
        if (this.productId) {
          if(this.productId.toLowerCase() == 'Add'.toLowerCase()){
            this.isNewProduct = true;
            this.header = "Add New product";
            this.setImage();
          } else {
            this.isNewProduct = false;
            this.header = "Edit product";
            this.service.getProductById(this.productId)
            .subscribe(
              (successResponse) => {
                this.product = successResponse;
                this.setImage();
              },
              (errorResponse) => {
                console.log(errorResponse)
              }
            );
          }
         
        }

      }
    );
  }
  onUpdate(): void {
    this.service.updateProduct(this.productId, this.product)
    .subscribe({next:
     (successResponse) => {
       this.snackbar.open('Product updated successfully', undefined, {duration: 2000});
       setTimeout(() => {
         this.router.navigateByUrl('adminProduct01102023');
       }, 2000);
     },
     error: (errorResponse) => {
      console.log(errorResponse)
     }
  });
   }
  onDelete(): void {
    if (confirm("Are you sure to delete "+this.product.name)) {
     this.service.deleteProduct(this.productId)
     .subscribe({
      next: (successResponse) => {
        console.log(successResponse);
       this.snackbar.open('Product deleted successfully', undefined, {
        duration: 2000
       });
       setTimeout(() => {
        this.router.navigateByUrl('adminProduct01102023');
      }, 2000);
      },
      error: (errorResponse) => {
        console.log(errorResponse)
      } 
    });
    }
   }
   onAdd(): void {
     this.service.addproduct(this.product)
     .subscribe({next: 
      (successResponse) => {
        this.snackbar.open('Product Added successfully', undefined, {
          duration: 2000
         });
         setTimeout(() => {
          this.router.navigateByUrl('adminProduct01102023');
         // this.router.navigateByUrl(`admin/${successResponse.id}`);
        }, 2000);
      },
      error: (errorResponse) => {
        console.log(errorResponse)
      }
    })
   }
   uploadImage(event: any): void {
    if(this.productId) { 
      const file: File = event.target.files[0];
      this.service.uploadImage(this.productId, file)
      .subscribe({
        next: (successResponse) => {
          this.product.pictureUrl = successResponse;
          this.setImage();
         // this.snackbar.open('Profile Image Updated', undefined, {duration: 2000});
         this.router.navigateByUrl('adminProduct01102023');
        },
        error:(errorResponse) => {
          console.log(errorResponse)
        }
    })
    }
   }
    private setImage(): void {
         if (this.product.pictureUrl) {
           this.displayProfileImageUrl = this.service.getImagePath(this.product.pictureUrl);
         }else {
            this.displayProfileImageUrl = '/assets/images/resources/spices3.jpg';
         }
      
    }
    reloadPage() {
      setTimeout(()=>{
        window.location.reload();
      }, 100);
  }

}
