<section class="top-bar-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="flex-box-three">
                    <div class="top-left clearfix textline">
                        <div class="state-box">
                            <div class="state-content float-left">
                                <!--Start single state-->
                                <div class="state" id="value1">
                                    <ul class="clearfix">
                                        <li>
                                            <div class="icon-box">
                                                <a href="https://www.facebook.com/kirar.kirar.37625"  target="_blank"><span class="fa fa-facebook"></span> </a>
                                            </div>
                                           <div class="text-box">
                                               <p>f</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon-box">
                                                 <p><a href="https://www.instagram.com/kirar_coffee_tea_honey/?igshid=YmMyMTA2M2Y%3D" target="_blank"><span class="fa fa-instagram"></span></a></p>    
                                            </div>
                                            <div class="text-box">
                                               <p>i</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="icon-box">
                                                <span class="flaticon-note"></span>    
                                            </div>
                                            <div class="text-box">
                                               <a href="contact" target="_blank"><p>orderkirar@gmail.com</p></a>
                                              
                                            </div>
                                        </li>
                                    </ul>    
                                </div>
                                <!--End single state-->
                            </div>
                      
                        </div>
                    </div>
                    <div class="top-right clearfix" style="padding-right: 10px">
                        <ul class="top-menu float-left">
                            <ng-container *ngIf="(accountService.currentUser$ | async) === null">
                                <a routerLink="/account/login" class="btn btn-outline-warning me-2 topnav" style="color: orange;font-weight: 900;">Login</a>&nbsp;
                                <a routerLink="/account/register" class="btn btn-outline-warning me-3 topnav" style="color: orange;font-weight: 900;">Sign up</a>
                            </ng-container>
                           <ng-container *ngIf="(accountService.currentUser$ | async) as user" >
                            <div class="dropdown ms-3 me-5" style="cursor: pointer;" dropdown>
                                <li><a href=""><h4 style="color: #fff">Welcome {{user.DisplayName}}</h4></a></li> 
                             <li class="btn warning"> <a (click)="accountService.logout()" class="fa fa-sign-out mr-4" style="color: #ffffff; size: 5">
                            Logout
                            </a> </li>
                            </div>
                          </ng-container>
                         
                        </ul>

                    </div> 
              
                </div>    
            </div>
        </div>
    </div>
</section>
<!--End Top bar area --> 
 
<!--Start header area-->
<header class="header-area">
    <!--Start header upper-->
   <!--<div class="header-upper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="inner-content">
                        <div class="flex-box-three">
                            <div class="header-upper-left clearfix">
                                <div class="state-box">
                                    <div class="state-content float-right">
                                        
                                        <div class="state" id="value1">
                                            <ul class="clearfix">
                                                <li>
                                                    <div class="icon-box">
                                                        <span class="flaticon-technology"></span>    
                                                    </div>
                                                    <div class="text-box">
                                                        <p>202-999-9999</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="icon-box">
                                                        <span class="flaticon-note"></span>    
                                                    </div>
                                                    <div class="text-box">
                                                        <p>orderkirar@gmail.com</p>
                                                    </div>
                                                </li>
                                            </ul>    
                                        </div>
                                       
                                    </div>
                                </div>    
                            </div>
                            <div class="header-upper-right clearfix">
                                <p>Stay Connected:</p>
                                <ul class="sociallinks-style-one">
                                    <li><a href="https://www.facebook.com/kirar.kirar.37625"  target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.instagram.com/kirar_coffee_tea_honey/?igshid=YmMyMTA2M2Y%3D" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <!--End header upper-->
    <!--Start header lawer-->
 
    <div class="header-lower">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="header-lawer-content clearfix">
                        <div class="header-lower-left">
                            <div class="logo">
                                <a href="/index.html">
                                    <img src="assets/images/resources/kir.jpg" alt="Awesome Logo">
                                </a>
                            </div>
                        </div>
                        <div class="header-lower-right clearfix">
                            <nav class="main-menu clearfix">
                                <div class="navbar-header clearfix">   	
                                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse" style="background: orange;">
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                </div>
                              
                                <div class="navbar-collapse collapse clearfix " >
                                    <ul class="navigation clearfix">
                                        <li class="current"> <a href="/index.html">Home</a></li>
                                        <li><a href="/about">About Us</a> </li>
                                        <li> <a routerLink="/shop" class="nav-item nav-link">Products</a></li>
                                        <li><a href="/contact">Contact Us</a></li>
                                        <!--========================================================-->
                                        <ng-container *ngIf="(accountService.currentUser$ | async)">
                                        <li class="dropdown"><a>View Order  </a>
                                            <ul>
                                                <li class="fa fa-shopping-cart fa-2x"><a href="/basket" >View Basket</a></li>
                                                <li class="fa fa-history"><a href="/orders">View Orders</a></li>
                                                <li class="fa fa-sign-out me-3"><a (click)="accountService.logout()" class="fa fa-sign-out mr-3"> Logout</a></li>
                                               
                                            </ul>
                                        </li>
                                        </ng-container>
                                       <!--==========================================================-->
                                    </ul>
                                </div>
                         
                            </nav>
                            <div class="mainmenu-middle clearfix">
                                <ul class="clearfix">
                                  
                                    <li> 
                                        <div class="col-md-4">
                                            <div class="user" style="margin-right: 100px">
                                              
                                           <a  routerLink='/basket' href="" class="btn cart" >
                                              <i class="fa fa-shopping-cart fa-2x"  style="color:orange"></i>
                                              <div *ngIf="(basketService.basketSource$ | async) as basket" class="cart-no">{{getCount(basket.items)}}</div>
                                            </a>
                                       
                                               
                                            </div>
                                        </div>
                                     
                                    </li>
                                    
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--End header lawer-->
    
    <!---Start sticky header-->
    <div class="sticky-header" style="background: rgb(56, 34, 34); ">
        <div class="container" >
            <div class="row">
                <div class="col-md-12">
                    <div class="left-colum pull-left">
                        <div class="logo">
                            <a href="index.html">
                                <img src="assets/images/resources/kir.jpg" alt="Awesome Logo">
                            </a>
                        </div>    
                    </div>
                    <div class="right-colum pull-right">
                        <nav class="main-menu clearfix">
                            <div class="navbar-header clearfix">   	
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                            </div>
                            <div class="navbar-collapse collapse clearfix">
                                <ul class="navigation clearfix">
                                    <a href="index.html" [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item nav-link active">Home</a>
                                   <li class="current" [routerLink]="['/']"><a href="index.html">Home</a></li>
                                    <li><a href="/about">About Us</a> </li>
                                     <li> <a href="" routerLink="/shop" class="nav-item nav-link">Products</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                    <li> <a  routerLink='/basket' href="" class="btn cart" >
                                        <i class="fa fa-shopping-cart fa-2x"  style="color:orange"></i>
                                        <div *ngIf="(basketService.basketSource$ | async) as basket" class="cart-no" style="padding-top: 3px;">{{getCount(basket.items)}}</div>
                                      </a></li>
                                </ul>
                            </div>
                        </nav>   
                    </div>
                </div>
            </div>    
        </div>
    </div>
    <!---End sticky header-->
</header>  


