import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
  } from '@angular/common/http';
import { Injectable } from '@angular/core';
  import { Observable, take } from 'rxjs';
import { AccountService } from 'src/app/account/account.service';
  
  @Injectable()
  export class JwtInterceptor implements HttpInterceptor {
    token?: string;
    constructor(private accountService: AccountService) {}
  
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      this.accountService.currentUser$.pipe(take(1)).subscribe({next: user => this.token = user?.token})
      
      if (this.token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`
          }
        })
      }
      return next.handle(request);
    }
  }