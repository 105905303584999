import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from 'src/app/shop/shop.service';
import { Productvariance } from 'src/app/shared/models/productvariance';
//import { IRoastingOption } from 'src/app/shared/models/roastOption';

@Component({
  selector: 'app-roastoption',
  templateUrl: './roastoption.component.html',
  styleUrls: ['./roastoption.component.css']
})
export class RoastoptionComponent {
   variance: Productvariance[];
  //filterString = '';
  displayedColumns: string[] = ['id','weightCoffee','priceCoffee','edit','delete'];
  dataSource: MatTableDataSource<Productvariance> = new MatTableDataSource<Productvariance>();

 @ViewChild(MatPaginator) matPaginator: MatPaginator;
 @ViewChild(MatSort) sort: MatSort;
  constructor(private http: HttpClient, public service : AdminService, 
    private route: ActivatedRoute, private router: Router) { } 
    ngOnInit(): void {
      this.GetOrderItem();
    }
    GetOrderItem() {
      this.service.getRoastingoption()
      .subscribe({next:
        (successResponse: any) => {
           this.variance = successResponse;
           this.dataSource = new MatTableDataSource<Productvariance>(this.variance);
          if (this.matPaginator) {
            this.dataSource.paginator = this.matPaginator;
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse)
        }
      });
      }
}
